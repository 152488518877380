import React from "react";
import CRUD from "../components/CRUD/crud";
import { GroupBtn, EditBtn, StateBtn, MoveBtn } from "../components/CRUD/btn";
import {
  EditInput,
  EditSelect,
  EditTime,
  EditCheckbox,
  EditBranch,
} from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";
import { formatPrice } from "../helpers";
import { NavLink } from "react-router-dom";
import Lang from "../components/Lang/lang";

export default class Item extends CRUD {
  constructor(props) {
    super(props);
    this.key = 1;
    this.object = "item"; // server side object name
    this.state = {
      title: "Items",
      create_base_article: false,
      columns: [
        {
          key: "pos",
          label: "Order",
          numeric: true,
          render: (v, row) => {
            return (
              <MoveBtn
                onClick={() => {
                  if (v === this.state.moving) {
                    this.setState({ moving: 0, movingPos: 0 });
                  } else if (this.state.moving > 0) {
                    this.onSwap(this.state.moving, row[this.key]);
                    this.setState({ moving: 0, movingPos: 0 });
                  } else {
                    this.setState({ moving: row[this.key], movingPos: v });
                  }
                }}
                id={row[this.key]}
                moving={this.state.moving}
              />
            );
          },
        },
        { key: "id", label: "ID", numeric: true },
        { key: "barcode", label: "Barcode" }, //key as in db name
        { key: "prep_mins", label: "Prep[min]" }, //key as in db name
        { key: "name", label: "Name" }, //key as in db name
        { key: "menu", label: "Menu" },
        {
          key: "vat",
          label: "Vat",
          render: (v) => v.toFixed(0) + "%",
          numeric: true,
        },
        { key: "price1", label: "Price", render: formatPrice, numeric: true },
        { key: "from", label: "Happy from" },
        { key: "to", label: "Happy to" },
        {
          key: "price2",
          label: "Happy price",
          render: formatPrice,
          numeric: true,
        },
        {
          key: "id",
          label: "List ingredient's",
          render: (v) => (
            <NavLink to={"/ingredient/" + v}>
              <Lang>List ingredient's</Lang>
            </NavLink>
          ),
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  onSwap = (id, withId) => {
    this.query({
      [this.object]: {
        swap: {
          id: id,
          with_id: withId,
          fields: this.state.columns.map((c) => c.key),
        },
      },
    }).then((data) => {
      if (data[this.object].swap) {
        this.swapRows(data[this.object].swap);
      }
    });
  };

  createForm = (data) => {
    let obj = data[this.object].one || {};
    obj["menu_id"] = parseInt(this.props.match.params.id, 10);
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="barcode" />
        <EditInput name="name" />
        <EditSelect name="menu_id" options={data.menu.read} />
        <EditInput name="price1" type="number" />
        <EditInput name="price2" type="number" />
        <EditInput name="prep_mins" type="number" />
        <EditTime name="from" />
        <EditTime name="to" />
        <EditCheckbox name="star" />
        <EditSelect name="unit_id" options={data.unit.read} />
        <EditSelect name="vat_id" options={data.vat.read} />
        <EditSelect
          name="type"
          options={[
            [0, "Artikull Baze"],
            [1, "Artikull"],
            [2, "Oferte"],
          ]}
        />
        <EditBranch name="branch" branches={data.branch.read} />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      menu: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      vat: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      unit: {
        read: {
          fields: ["id", "name"],
        },
      },
      branch: { read: { fields: ["id", "name"] } },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data, image: false });
    });
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["menu_id = " + id],
        },
      },
      menu: {
        read: {
          fields: ["name"],
          where: ["id = " + id],
        },
      },
    }).then((data) => {
      let m = data.menu.read[0];
      this.setState({
        params: { sub_category: m[0] },
        data: data[this.object].read,
      });
    });
  }
}
