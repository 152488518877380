import React, { createContext } from "react";
import { render } from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Post } from "./api";
import Auth from "./auth/auth";
import Loading from "./auth/loading";
import AppProvider from "./components/AppProvider/AppProvider";
import { SetLang } from "./components/Lang/lang";
import Main from "./main";
import registerServiceWorker from "./registerServiceWorker";
import Routes from "./routes";

export const LangCtx = createContext("AL");
export let Currency = "€";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      auth: false,
      lang: localStorage.getItem("lang"),
      state: 1,
      routes: { items: ["/"] },
    };
  }

  componentDidMount() {
    Post("/refresh")
      .then((data) => {
        Currency = data.currency;
        let _routes = [];
        let accessRoutes = data.user.Access.routes || [];
        Routes.items.forEach((r) => {
          if (typeof r.path === "string") {
            if (accessRoutes.includes(r.path)) {
              _routes.push(r);
            }
          } else {
            _routes.push(r);
          }
        });

        this.setState({
          loading: false,
          auth: true,
          state: data.app_state,
          routes: { items: _routes },
        });
      })
      .catch((err) => {
        this.setState({ loading: false, auth: false });
      });

    fetch("/t")
      .then((data) => data.json())
      .then((translate) => {
        SetLang(translate);
      });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else if (!this.state.auth) {
      return (
        <AppProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/">
                <Auth
                  onLoggedIn={(user, lang) => {
                    let _routes = [];
                    let accessRoutes = user.Access.routes || [];
                    Routes.items.forEach((r) => {
                      if (typeof r.path === "string") {
                        if (accessRoutes.includes(r.path)) {
                          _routes.push(r);
                        }
                      } else {
                        _routes.push(r);
                      }
                    });
                    this.setState({ auth: true, routes: { items: _routes }, lang: lang });
                  }}
                />
              </Route>
            </Switch>
          </BrowserRouter>
        </AppProvider>
      );
    } else {
      return (
        <LangCtx.Provider value={this.state.lang}>
          <AppProvider>
            <BrowserRouter>
              <Switch>
                <Route path="/auth">
                  <Auth
                    onLoggedIn={(user, lang) => {
                      this.setState({ auth: true, lang: lang });
                    }}
                  />
                </Route>
                <Route path="/">
                  <Main state={this.state.state} routes={this.state.routes} />
                </Route>
              </Switch>
            </BrowserRouter>
          </AppProvider>
        </LangCtx.Provider>
      );
    }
  }
}

render(<Index />, document.getElementById("root"));

registerServiceWorker();
