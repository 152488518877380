import React from "react";
import { EditBtn, GroupBtn, StateBtn } from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import { EditInput, EditSelectAutocomplete } from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";

export default class TrasnferItem extends CRUD {
  constructor(props) {
    super(props);
    this.object = "transfer_item"; // server side object name
    this.state = {
      title: "Transfer Items",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Article" },
        {
          key: "amount",
          label: "Qty",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
      params: {},
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    obj["transfer_id"] = parseInt(this.props.match.params.id, 10);
    return (
      <FormDialog crud={this} data={obj}>
        <EditSelectAutocomplete name="item_id" options={data.item.read} />
        <EditInput name="amount" type="number" />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      item: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data });
    });
  };

  componentDidMount() {
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["transfer_id = " + this.props.match.params.id],
        },
      },
      transfer: {
        read: {
          fields: ["from", "to", "issued_on"],
          where: ["id = " + this.props.match.params.id],
        },
      },
    }).then((data) => {
      let t = data.transfer.read[0];
      this.setState({
        params: { from: t[0], to: t[1], issued_on: t[2] },
        data: data[this.object].read,
      });
    });
  }
}
