import React from "react";
import { EditBtn, GroupBtn, StateBtn } from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import {
  EditCheckbox, EditInput, EditSelectAutocomplete
} from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";

export default class Ingredient extends CRUD {
  constructor(props) {
    super(props);
    this.object = "item_ingredient"; // server side object name
    this.state = {
      title: "Ingredients",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Name" }, //key as in db name
        {
          key: "amount",
          label: "Qty",
          render: (v) => v.toFixed(5),
          numeric: true,
        },
        {
          key: "purchase_last_price",
          label: "Cmimi i fundit i blerjes",
          render: (v) => v.toFixed(5),
          numeric: true,
        },
        {
          key: "purchase_price",
          label: "Cmimi i Blerjes",
          render: (v) => v.toFixed(5),
          numeric: true,
        },
        {
          key: "purchase_total",
          label: "Vlera e Blerjes",
          render: (v) => v.toFixed(5),
          numeric: true,
          sum: true,
        },
        {
          key: "removable",
          label: "Base",
          render: (v) => (v === 1 ? "No" : "Yes"),
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    obj["item_id"] = parseInt(this.props.match.params.id, 10);
    return (
      <FormDialog crud={this} data={obj}>
        <EditSelectAutocomplete name="item_raw_id" options={data.item.read} />
        <EditInput name="amount" type="number" />
        <EditCheckbox name="removable" />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      item: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data });
    });
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["item_id = " + id],
        },
      },
      item: {
        read: {
          fields: ["name", "price1"],
          where: ["id = " + id],
        },
      },
    }).then((data) => {
      let m = data.item.read[0];
      this.setState({
        params: { item: m[0], price: m[1] },
        data: data[this.object].read,
      });
    });
  }
}
