import React from "react";
import { ButtonGroup, Button, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Lang from "../Lang/lang";

const useStyles = makeStyles(theme => ({
  key: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    fontWeight: 400
  },
  val: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    fontWeight: 500
  }
}));

export default function Info(props) {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant="h6">
          <Lang>{props.title}</Lang>
        </Typography>
      </Grid>
      {Object.keys(props.info || {}).map(k => (
        <Grid key={k} item style={{ display: "flex", alignItems: "center" }}>
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Button className={classes.key}>{k}</Button>
            <Button className={classes.val}>{props.info[k]}</Button>
          </ButtonGroup>
        </Grid>
      ))}
    </Grid>
  );
}
