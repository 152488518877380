import React from "react";
import CRUD from "../components/CRUD/crud";
import { GroupBtn, EditBtn, StateBtn } from "../components/CRUD/btn";
import { EditInput, EditSelect } from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";
import { formatPrice } from "../helpers";

export default class DeliveryCost extends CRUD {
  constructor(props) {
    super(props);
    this.object = "delivery_cost";
    this.state = {
      title: "Delivery Cost",
      columns: [
        { key: "id", numeric: true },
        { key: "total_from", numeric: true, render: formatPrice },
        { key: "total_to", numeric: true, render: formatPrice },
        { key: "distance_from", numeric: true },
        { key: "distance_to", numeric: true },
        { key: "cost", numeric: true, render: formatPrice },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="total_from" type="number" />
        <EditInput name="total_to" type="number" />
        <EditInput name="distance_from" type="number" />
        <EditInput name="distance_to" type="number" />
        <EditInput name="cost" type="number" />
        <EditSelect name="branch_id" options={data.branch.read} />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      branch: { read: { fields: ["id", "name"] } },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data });
    });
  };
}
