import React from "react";
import Drawer from "@material-ui/core/Drawer";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { notificationCenterWidth } from "../../styleVariables";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow
} from "@material-ui/core";
//import useMountEffect from '../../mountEffect';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: notificationCenterWidth,
    maxWidth: notificationCenterWidth,
    [theme.breakpoints.down("sm")]: {
      top: "56px!important",
      height: "calc(100vh - 56px)"
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px!important",
      height: "calc(100vh - 64px)"
    },
    zIndex: theme.zIndex.drawer + 99
  },
  modal: {
    [theme.breakpoints.down("sm")]: {
      top: "56px"
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px"
    },
    zIndex: theme.zIndex.drawer + 99
  },
  backdrop: {
    [theme.breakpoints.down("sm")]: {
      top: "56px"
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px"
    }
  },
  container: {
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
    zIndex: 1,
    flexGrow: 1
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: "50%",
    color: theme.palette.text.primary
  },
  padding: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1) * 2,
      paddingRight: theme.spacing(1) * 2,
      paddingTop: theme.spacing(1) * 2,
      paddingBottom: theme.spacing(1) * 2
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(1) * 3,
      paddingRight: theme.spacing(1) * 3,
      paddingTop: theme.spacing(1) * 2,
      paddingBottom: theme.spacing(1) * 2
    }
  }
}));

const NotificationCenter = ({
  notificationsOpen,
  toogleNotifications,
  alarm
}) => {
  const classes = useStyles();
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={notificationsOpen}
      ModalProps={{
        keepMounted: false,
        className: classes.modal,
        BackdropProps: {
          className: classes.backdrop
        },
        onBackdropClick: toogleNotifications
      }}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Article</TableCell>
            <TableCell align="right">Stock</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alarm.map(row => (
            <TableRow key={row[0]}>
              <TableCell>{row[0]}</TableCell>
              <TableCell align="right">
                {row[1].toFixed(3) + " [" + row[2] + "]"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Drawer>
  );
};

NotificationCenter.propTypes = {
  notificationsOpen: PropTypes.bool,
  toogleNotifications: PropTypes.func
};

export default NotificationCenter;
