import { Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import { Line } from "react-chartjs-2";
import {
  ExcelBtn,
  GenerateBtn, GroupBtn,
  PrintBtn
} from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import {
  EditCheckbox, EditDateTime,
  EditSelect
} from "../components/CRUD/fields";
import { FormInline } from "../components/CRUD/form";
import Info from "../components/CRUD/info";
import CrudToolbar from "../components/CRUD/toolbar";
import Lang from "../components/Lang/lang";
import { formatPrice } from '../helpers';

export default class Sale extends CRUD {
  constructor(props) {
    super(props);
    this.object = "report";
    this.state = {
      title: "Sale totals",
      tabIdx: 0,
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Name" },
        { key: "category", label: "Category" },
        { key: "sub_category", label: "SubCategory" },
        { key: "qty", label: "Quantity", sum: true, numeric: true },
        {
          key: "price",
          label: "Price",
          numeric: true,
          render: formatPrice,
        },
        {
          key: "total",
          label: "Total",
          sum: true,
          numeric: true,
          render: formatPrice,
        },
      ],
      params: { from: new Date(), to: new Date() },
      options: {
        category: "",
        user: "",
        menu: "",
      },
      menu_categories: [],
      menus: [],
      users: [],
      graph: false,
      graphData: {
        labels: [],
        datasets: [],
      },
    };
  }

  generate = () => {
    this.setState({ data: null });

    let where = [
      "issued_on > " + this.state.params.from.toISOString(),
      "issued_on < " + this.state.params.to.toISOString(),
    ];
    let params = this.state.params;
    if (this.state.options.category && this.state.tabIdx !== 0) {
      where.push("menu_category_id = " + this.state.options.category);
      params["category"] = this.state.menu_categories.find(
        (mc) => mc[0] === this.state.options.category
      )[1];
    } else {
      delete params["category"];
    }
    if (this.state.options.menu && this.state.tabIdx === 1) {
      where.push("menu_id = " + this.state.options.menu);
      params["menu"] = this.state.menus.find(
        (mc) => mc[0] === this.state.options.menu
      )[1];
    } else {
      delete params["menu"];
    }
    if (this.state.options.user && this.state.tabIdx === 2) {
      where.push("user_id = " + this.state.options.user);
      params["user"] = this.state.users.find(
        (mc) => mc[0] === this.state.options.user
      )[1];
    } else {
      delete params["user"];
    }
    this.setState({ params: params });

    if (this.state.graph) {
      this.query({
        [this.object]: {
          SaleGraph: {
            fields: ["issued_on_str", "total", "amount"],
            where: where,
          },
        },
      }).then((data) => {
        let nData = data[this.object].SaleGraph;
        this.setState({
          graphData: {
            labels: nData.map((r) => r[0]),
            datasets: [
              {
                label: "Sale",
                data: nData.map((r) => r[1]),
                fill: false,
                borderColor: "#EC932F",
              },
              {
                label: "Qty",
                data: nData.map((r) => r[2]),
                fill: false,
                borderColor: "#EC93FF",
              },
            ],
          },
        });
      });
    } else {
      this.query({
        [this.object]: {
          sale: {
            fields: this.state.columns.map((c) => c.key),
            where: where,
          },
        },
      }).then((data) => {
        this.setState({ data: data[this.object].sale });
      });
    }
  };

  forms = () => [
    [],
    [
      <EditSelect
        name="category"
        data={this.state.options}
        options={this.state.menu_categories}
      />,
      <EditSelect
        name="menu"
        data={this.state.options}
        options={this.state.menus}
      />,
    ],
    [
      <EditSelect
        name="user"
        data={this.state.options}
        options={this.state.users}
      />,
      <EditSelect
        name="category"
        data={this.state.options}
        options={this.state.menu_categories}
      />,
    ],
  ];

  titles = () => ["Sale totals", "Menu totals", "Waiter totals"];

  toolbar = () => (
    <CrudToolbar
      leftForm={
        <FormInline>
          <EditDateTime name="from" data={this.state.params} />
          <EditDateTime name="to" data={this.state.params} />
          {this.forms()[this.state.tabIdx]}
          <EditCheckbox
            name="graph"
            data={this.state}
            onChange={(val) => {
              this.setState({ data: [] });
            }}
          />
          <GenerateBtn onClick={this.generate} />
        </FormInline>
      }
      tabs={
        <Tabs
          value={this.state.tabIdx}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, idx) => {
            this.setState({
              tabIdx: idx,
              data: [],
              graphData: { labels: [], datasets: [] },
            });
          }}
        >
          <Tab
            label={
              <Typography>
                <Lang>SALE</Lang>
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                <Lang>MENU</Lang>
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                <Lang>WAITER</Lang>
              </Typography>
            }
          />
        </Tabs>
      }
      left={<Info title={this.titles()[this.state.tabIdx]} />}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
        </GroupBtn>
      }
    />
  );

  body = () => {
    return this.state.graph ? (
      <Line data={this.state.graphData} height={50} />
    ) : null;
  };

  componentDidMount() {
    this.query({
      menu_category: { read: { fields: ["id", "name"] } },
      menu: { read: { fields: ["id", "name"] } },
      user: { read: { fields: ["id", "name"], where: ["type_id = 3"] } },
    }).then((data) => {
      this.setState({
        menu_categories: [[0, "ALL"]].concat(data.menu_category.read),
        menus: [[0, "ALL"]].concat(data.menu.read),
        users: [[0, "ALL"]].concat(data.user.read),
      });
    });
    this.setState({ data: [] });
  }
}
