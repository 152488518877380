import { subDays } from "date-fns";
import React from "react";
import { AddBtn, EditBtn, ExcelBtn, GroupBtn, PrintBtn, StateBtn } from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import { EditDateTime, EditInput, EditSelect } from "../components/CRUD/fields";
import FormDialog, { FormInline } from "../components/CRUD/form";
import Info from "../components/CRUD/info";
import CrudToolbar from "../components/CRUD/toolbar";
import Lang from "../components/Lang/lang";
import { formatDateTime, formatPrice } from "../helpers";

export default class RegisterTrx extends CRUD {
  constructor(props) {
    super(props);
    this.object = "trx";
    this.state = {
      title: "Transactions",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "user", label: "User" },
        { key: "note", label: "Note" },
        { key: "exec_on", label: "ExecOn", render: formatDateTime },
        { key: "amount", label: "Amount", render: formatPrice, numeric: true },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
      fromTo: { from: subDays(new Date(), 2), to: new Date()},
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    //obj["register_id"] = parseInt(this.props.match.params.id, 10);
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="note" />
        <EditInput name="amount" type="number" />
        <EditDateTime name="exec_on" />
        {obj.id
          ? null
          : [
              <EditSelect
                name="from_register_id"
                options={data.register.read}
              />,
              <EditSelect name="register_id" options={data.register.read} />,
            ]}
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      register: {
        read: {
          fields: ["id", "name"],
        },
      },
    };

    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }

    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data });
    });
  };

  toolbar = () => (
    <CrudToolbar
      count={(this.state.data || []).length}
      left={
        <Info
          info={this.state.params}
          title={<Lang>{this.state.title}</Lang>}
        />
      }
      onSearch={(e) => this.setState({ keyword: e.target.value.toLowerCase() })}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
          {this.createForm ? <AddBtn object={this.object} onClick={() => this.onEdit({})} /> : null}
        </GroupBtn>
      }
      leftForm={
        <FormInline>
          <EditDateTime name="from" data={this.state.fromTo} onEdit={this.doQuery}/>
          <EditDateTime name="to" data={this.state.fromTo} onEdit={this.doQuery}/>
        </FormInline>
      }
    />
  );

  doQuery = () => {
    this.setState({ data: [] });
    const id = this.props.match.params.id;
    let q = {
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["register_id = " + id, "exec_on >= " + this.state.fromTo.from.toISOString(), "exec_on <= " + this.state.fromTo.to.toISOString()],
        },
      },
      register: {
        read: {
          fields: ["name"],
          where: ["id = " + id],
        },
      },
    };

    this.query(q).then((data) => {
      this.setState({
        data: data[this.object].read,
        params: { register: data.register.read[0][0] },
      });
    });
  }

  componentDidMount() {
    this.doQuery();
  }
}
