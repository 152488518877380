import React from 'react';
import { NavLink } from 'react-router-dom';
import { EditBtn, GroupBtn, StateBtn } from '../components/CRUD/btn';
import CRUD from '../components/CRUD/crud';
import { EditInput } from '../components/CRUD/fields';
import FormDialog from '../components/CRUD/form';
import { formatPrice } from '../helpers';

export default class Supplier extends CRUD {
  constructor(props) {
    super(props);
    this.object = 'supplier';
    this.state = {
      title: "Supplier",
      columns: [
        {key: "id", label: "ID", numeric: true},
        {key: "name", label: "Name"},
        {key: "NRF", label: "NRF"},
        {key: "NRB", label: "NRB"},
        {key: "NRT", label: "NRT"},
        {key: "address", label: "Address"},
        {key: "contact", label: "Contact"},
        {key: "debt", label: "Debt", sum: true, render: formatPrice, numeric: true},
        {key: "id", label: "List Items", render: (v) => <NavLink to={"/supplier/"+v}>List</NavLink>},
        {key: "id", label: "List Purchases", render: (v) => <NavLink to={"/supplier/purchase/"+v}>List</NavLink>},
        {key: "state", label: "Action", render: (v, row) => (
          <GroupBtn>
            <EditBtn onClick={() => this.onEdit(row)}/>
            <StateBtn state={v} onClick={(v) => this.onSaveForm(row, {state: v})}/>
          </GroupBtn>
        )},
      ]
    }
  }
//ctrl + d select the same
  createForm = (data) => {
    let obj = data[this.object].one;
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name='name'/>
        <EditInput name='NRF'/>
        <EditInput name='NRB'/>
        <EditInput name='NRT'/>
        <EditInput name='address'/>
        <EditInput name='contact'/>
        <EditInput name='debt' type='number'/>
      </FormDialog>
    );
  }
}