import React from "react";
import CRUD from "../components/CRUD/crud";
import { GroupBtn, EditBtn, StateBtn } from "../components/CRUD/btn";
import {
  EditInput,
  EditBranch,
  EditMultipleSelect,
} from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";

export default class Option extends CRUD {
  constructor(props) {
    super(props);
    this.object = "item_option";
    this.state = {
      title: "Options",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "name" },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    return (
      <FormDialog crud={this} data={obj}>
        <EditMultipleSelect name="items" options={data.categories} />
        <EditInput name="name" />
        <EditBranch name="branch" branches={data.branch.read} />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      branch: { read: { fields: ["id", "name"] } },
      item: {
        read: { fields: ["id", "name", "menu"], where: ["menu_id != 0"] },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    console.log(req);
    this.query(req).then((data) => {
      let categ = {};
      data.item.read.forEach((row) => {
        const m = row[2];
        if (categ[m]) {
          categ[m].push(row);
        } else {
          categ[m] = [row];
        }
      });
      data.categories = categ;
      this.setState({ showForm: true, formData: data });
    });
  };
}
