import { Fade, NativeSelect } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Currency } from "..";
import { Post } from "../api";
import Lang from "../components/Lang/lang";


const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    background: "url(/static/images/bee.svg)",
    backgroundSize: "cover",
    //backgroundColor: theme.palette.primary.main
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center",
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },
  fullWidth: {
    width: "100%",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Auth = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [logingIn, setLogingIn] = useState(false);
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [lang, setLang] = useState(localStorage.getItem("lang") || "AL");
  const [fiscal, setFiscal] = useState("");

  const tryLogin = () => {
    setLogingIn(true);
    Post("/auth", {
      user: user,
      pass: pass,
      nr_fiscal: fiscal,
    })
      .then((user) => {
        localStorage.setItem("lang", lang);
        props.onLoggedIn(user, lang);
        Post("/refresh")
        .then((data) => {
          Currency = data.currency;
        })
        history.push("/");
      })
      .catch((msg) => {
        setLogingIn(false);
      });
  };

  return (
    <Fade in={true}>
      <div className={classNames(classes.session, classes.background)}>
        <div className={classes.content}>
          <div className={classes.wrapper}>
            <Card>
              <CardContent>
                <form>
                  <div
                    className={classNames(classes.logo, `text-xs-center pb-xs`)}
                  >
                    <img
                      src="/static/images/logo-dark.svg"
                      alt=""
                      className="block"
                      height="100px"
                    />
                    <Typography variant="caption">BEE IT © 2020</Typography>
                  </div>
                  <TextField
                    id="nr_fiscal"
                    label="Nr Fiscal"
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={(e) => setFiscal(e.target.value)}
                  />
                  <TextField
                    id="username"
                    label="Username"
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={(e) => setUser(e.target.value)}
                  />
                  <TextField
                    id="password"
                    label="Password"
                    className={classes.textField}
                    type="password"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={(e) => setPass(e.target.value)}
                  />
                  <NativeSelect
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={lang}
                    onChange={(e) => setLang(e.target.value)}
                  >
                    <option value="AL">Shqip</option>
                    <option value="EN">English</option>
                    <option value="DE">Deutsch</option>
                  </NativeSelect>
                  <FormControlLabel
                    control={<Checkbox value="checkedA" />}
                    label="Stayed logged in"
                    className={classes.fullWidth}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    disabled={logingIn}
                    onClick={(e) => {
                      e.preventDefault();
                      tryLogin();
                    }}
                  >
                    <Lang>{logingIn ? "Loging in..." : "Login"}</Lang>
                  </Button>
                  <div className="pt-1 text-md-center">
                    <Link to="/forgot">
                      <Button>
                        <Lang>Forgot password?</Lang>
                      </Button>
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="/signup">
                      <Button>
                        <Lang>Create new account</Lang>
                      </Button>
                    </Link>
                  </div>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Auth;
