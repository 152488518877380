import React from "react";
import CRUD from "../components/CRUD/crud";
import { GroupBtn, EditBtn, StateBtn } from "../components/CRUD/btn";
import { EditInput, EditBranch } from "../components/CRUD/fields";
import FormDialog, { FormDialogBody } from "../components/CRUD/form";
import { NavLink } from "react-router-dom";
import Lang from "../components/Lang/lang";

export default class MenuCategory extends CRUD {
  constructor(props) {
    super(props);
    this.object = "menu_category";
    this.state = {
      title: "Category",
      image: false,
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "name" },
        {
          key: "id",
          label: "List sub categories",
          render: (v) => (
            <NavLink to={"/menu/" + v}>
              <Lang>List sub categories</Lang>
            </NavLink>
          ),
        },
        {
          key: "img",
          label: "Image",
          render: (v, row) => (
            <button
              onClick={() => {
                this.setState({
                  image: true,
                  showForm: true,
                  formData: { [this.object]: {}, row: row, img: v },
                });
              }}
            >
              Image
            </button>
          ),
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    if (this.state.image) {
      return (
        <FormDialogBody
          crud={this}
          title="Image"
          onSave={() => {
            if (this.state.img) {
              const formData = new FormData();
              formData.append("img", this.state.img);

              fetch("/u?t=menu_category&id=" + data.row[this.key], {
                method: "POST",
                body: formData,
              })
                .then((response) => {
                  response.text().then((file) => {
                    data.row[
                      this.state.columns.findIndex((c) => c.key === "img")
                    ] = file;
                    console.log(file);
                    this.setState({
                      img: undefined,
                      showForm: false,
                      formData: {},
                    });
                  });
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              this.setState({
                img: undefined,
                showForm: false,
                formData: {},
              });
            }
          }}
        >
          <>
            <img src={"/static/images/" + data.img} height="200" />
            <input
              type="file"
              name="img"
              accept="image/png"
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  this.setState({ img: e.target.files[0] });
                }
              }}
            />
          </>
        </FormDialogBody>
      );
    }
    let obj = data[this.object].one;
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="name" />
        <EditBranch name="branch" branches={data.branch.read} />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      branch: { read: { fields: ["id", "name"] } },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data, image: false });
    });
  };
}
