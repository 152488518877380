import { Chip } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { EditBtn, GroupBtn, StateBtn } from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import { EditDate, EditInput, EditSelect } from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";
import { formatDate, formatPrice } from "../helpers";

export default class Assets extends CRUD {
  constructor(props) {
    super(props);
    this.object = "purchase"; // server side object name
    this.state = {
      title: "Assets",
      columns: [
        { key: "id", label: "ID", numeric: true },
        {
          key: "invoice_nr",
          label: "InvoiceNr",
          render: (v, row) => (
            <Chip
              variant={row[9] === 2 ? "default" : "outlined"}
              size="small"
              label={v}
            />
          ),
        },
        { key: "issued_on", label: "IssuedOn", render: formatDate },
        { key: "supplier", label: "Supplier" },
        {
          key: "total",
          label: "Total",
          sum: true,
          render: formatPrice,
          numeric: true,
        },
        {
          key: "total_no_vat",
          label: "TotalNoVat",
          sum: true,
          render: formatPrice,
          numeric: true,
        },
        {
          key: "payed",
          label: "Payed",
          sum: true,
          render: formatPrice,
          numeric: true,
        },
        {
          key: "id",
          label: "List transactions",
          render: (v) => <NavLink to={"/assets/" + v + "/trx"}>List</NavLink>,
        },
        {
          key: "id",
          label: "List items",
          render: (v) => <NavLink to={"/assets/" + v}>List</NavLink>,
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => {
            if (row[6] > 0) {
              if (Math.abs(row[6] - row[4]) < 0.01) {
                return (
                  <Chip
                    color="secondary"
                    variant="outlined"
                    size="small"
                    label="Invoiced"
                  />
                );
              } else {
                return (
                  <Chip
                    color="primary"
                    variant="outlined"
                    size="small"
                    label="Half invoiced"
                  />
                );
              }
            } else {
              return (
                <GroupBtn>
                  <EditBtn onClick={() => this.onEdit(row)} />
                  <StateBtn
                    state={v}
                    onClick={(v) => this.onSaveForm(row, { state: v })}
                  />
                </GroupBtn>
              );
            }
          },
        },
      ],
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    obj["type"] = "ASSETS";
    return (
      <FormDialog crud={this} data={obj}>
        <EditSelect name="supplier_id" options={data.supplier.read} />
        <EditInput name="invoice_nr" />
        <EditDate name="issued_on" />
        <EditSelect name="branch_id" options={data.branch.read} />
      </FormDialog>
    );
  };

  onRowAdded = (row) => {
    this.props.history.push("/assets/" + row[0]);
  };

  onEdit = (row) => {
    let req = {
      supplier: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      branch: {
        read: { fields: ["id", "name"] },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data });
    });
  };

  componentDidMount() {
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["type = ASSETS"],
        },
      },
    }).then((data) => {
      this.setState({ data: data[this.object].read });
    });
  }
}
