import { Chip } from '@material-ui/core';
import React from "react";

export default function Invoiced({ total }) {
  if (Math.abs(total) < 0.01) {
    return (
      <Chip
        color="secondary"
        variant="outlined"
        size="small"
        label="Invoiced"
      />
    );
  } else {
    return (
      <Chip
        color="primary"
        variant="outlined"
        size="small"
        label="Half invoiced"
      />
    );
  }
}