import React from "react";
import { Line } from "react-chartjs-2";
import {
  ExcelBtn,
  GenerateBtn, GroupBtn,
  PrintBtn
} from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import { EditDateTime } from "../components/CRUD/fields";
import { FormInline } from "../components/CRUD/form";
import Info from "../components/CRUD/info";
import CrudToolbar from "../components/CRUD/toolbar";
import { formatDateTime, formatPrice } from "../helpers";

export default class SaleInvoice extends CRUD {
  constructor(props) {
    super(props);
    this.object = "report";
    this.state = {
      title: "Sale Invoice",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "invoice_nr", label: "Invoice Nr" },
        { key: "name", label: "Name" },
        { key: "client", label: "Client" },
        {
          key: "total",
          label: "Total",
          sum: true,
          numeric: true,
          render: formatPrice,
        },
        {
          key: "payed",
          label: "Payed",
          sum: true,
          numeric: true,
          render: formatPrice,
        },
        {
          key: "issued_on",
          label: "Issued On",
          render: (v) => formatDateTime(v),
        },
      ],
      params: { from: new Date(), to: new Date() },
    };
  }

  generate = () => {
    this.setState({ data: null });

    let where = [
      "issued_on > " + this.state.params.from.toISOString(),
      "issued_on < " + this.state.params.to.toISOString(),
    ];
    let params = this.state.params;
    this.setState({ params: params });

    this.query({
      [this.object]: {
        saleInvoice: {
          fields: this.state.columns.map((c) => c.key),
          where: where,
        },
      },
    }).then((data) => {
      this.setState({ data: data[this.object].saleInvoice });
    });
  };

  toolbar = () => (
    <CrudToolbar
      leftForm={
        <FormInline>
          <EditDateTime name="from" data={this.state.params} />
          <EditDateTime name="to" data={this.state.params} />
          <GenerateBtn onClick={this.generate} />
        </FormInline>
      }
      left={<Info title={this.state.title} />}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
        </GroupBtn>
      }
    />
  );

  body = () => {
    return this.state.graph ? (
      <Line data={this.state.graphData} height={50} />
    ) : null;
  };

  componentDidMount() {
    this.setState({ data: [] });
  }
}
