import { Chip } from "@material-ui/core";
import { subDays } from "date-fns";
import React from "react";
import { NavLink } from "react-router-dom";
import { AddBtn, EditBtn, ExcelBtn, GroupBtn, PrintBtn, StateBtn } from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import { EditDate, EditDateTime, EditInput, EditSelect } from "../components/CRUD/fields";
import FormDialog, { FormInline } from "../components/CRUD/form";
import Info from "../components/CRUD/info";
import Invoiced from "../components/CRUD/invoiced";
import CrudToolbar from "../components/CRUD/toolbar";
import Lang from "../components/Lang/lang";
import { formatDate, formatPrice } from "../helpers";

export default class Invoice extends CRUD {
  constructor(props) {
    super(props);
    this.object = "invoice"; // server side object name
    this.state = {
      title: "Invoice",
      columns: [
        { key: "id", label: "ID", numeric: true },
        {
          key: "invoice_nr",
          label: "InvoiceNr",
          render: (v, row) => (
            <Chip
              variant={row[9] === 2 ? "default" : "outlined"}
              size="small"
              label={v}
            />
          ),
        },
        { key: "issued_on", label: "IssuedOn", render: formatDate },
        { key: "client", label: "Client" },
        {
          key: "total",
          label: "Total",
          sum: true,
          render: formatPrice,
          numeric: true,
        },
        {
          key: "payed",
          label: "Payed",
          sum: true,
          render: formatPrice,
          numeric: true,
        },
        {
          key: "id",
          label: "List transactions",
          render: (v) => <NavLink to={"/invoice/" + v + "/trx"}>List</NavLink>,
        },
        {
          key: "id",
          label: "List items",
          render: (v) => <NavLink to={"/invoice/" + v}>List</NavLink>,
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => {
            if (row[5] > 0) {
              return <Invoiced total={row[5] - row[4]}/>
            } else {
              return (
                <GroupBtn>
                  <EditBtn onClick={() => this.onEdit(row)} />
                  <StateBtn
                    state={v}
                    onClick={(v) => this.onSaveForm(row, { state: v })}
                  />
                </GroupBtn>
              );
            }
          },
        },
      ],
      fromTo: { from: subDays(new Date(), 2), to: new Date()},
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    return (
      <FormDialog crud={this} data={obj}>
        <EditSelect name="client_id" options={data.client.read} />
        <EditInput name="invoice_nr" />
        <EditDate name="issued_on" />
        <EditSelect name="branch_id" options={data.branch.read} />
      </FormDialog>
    );
  };

  onRowAdded = (row) => {
    this.props.history.push("/invoice/" + row[0]);
  };

  onEdit = (row) => {
    let req = {
      client: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      branch: {
        read: { fields: ["id", "name"] },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data });
    });
  };

  toolbar = () => (
    <CrudToolbar
      count={(this.state.data || []).length}
      left={
        <Info
          info={this.state.params}
          title={<Lang>{this.state.title}</Lang>}
        />
      }
      onSearch={(e) => this.setState({ keyword: e.target.value.toLowerCase() })}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
          {this.createForm ? <AddBtn object={this.object} onClick={() => this.onEdit({})} /> : null}
        </GroupBtn>
      }
      leftForm={
        <FormInline>
          <EditDateTime name="from" data={this.state.fromTo} onEdit={this.doQuery}/>
          <EditDateTime name="to" data={this.state.fromTo} onEdit={this.doQuery}/>
        </FormInline>
      }
    />
  );

  doQuery = () => {
    this.setState({ data: [] });
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: [ "issued_on >= " + this.state.fromTo.from.toISOString(), "issued_on <= " + this.state.fromTo.to.toISOString()]
        },
      },
    }).then((data) => {
      this.setState({ data: data[this.object].read });
    });
  }

  componentDidMount() {
    this.doQuery();
  }
}
