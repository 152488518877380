import React from "react";
import CRUD from "../components/CRUD/crud";
import CrudToolbar from "../components/CRUD/toolbar";
import Info from "../components/CRUD/info";
import {
  GroupBtn,
  PrintBtn,
  ExcelBtn,
  GenerateBtn,
} from "../components/CRUD/btn";
import { EditDateTime } from "../components/CRUD/fields";
import { FormInline } from "../components/CRUD/form";
import { formatPrice, formatDateTime } from "../helpers";

export default class Delete extends CRUD {
  constructor(props) {
    super(props);
    this.object = "report";
    this.state = {
      title: "Deletes",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Name" },
        { key: "total", label: "Total", numeric: true, render: formatPrice },
        { key: "category", label: "Category" },
        { key: "waiter", label: "Waiter" },
        { key: "manager", label: "Manager" },
        { key: "deleted_on", label: "DeletedOn", render: formatDateTime },
      ],
      params: { from: new Date(), to: new Date() },
    };
  }

  generate = () => {
    this.setState({ data: null });

    let where = [
      "deleted_on > " + this.state.params.from.toISOString(),
      "deleted_on < " + this.state.params.to.toISOString(),
    ];
    this.query({
      [this.object]: {
        SaleDelete: {
          fields: this.state.columns.map((c) => c.key),
          where: where,
        },
      },
    }).then((data) => {
      this.setState({ data: data[this.object].SaleDelete });
    });
  };

  toolbar = () => (
    <CrudToolbar
      leftForm={
        <FormInline>
          <EditDateTime name="from" data={this.state.params} />
          <EditDateTime name="to" data={this.state.params} />
          <GenerateBtn onClick={this.generate} />
        </FormInline>
      }
      left={<Info title={this.state.title} />}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
        </GroupBtn>
      }
    />
  );

  componentDidMount() {
    this.setState({ data: [] });
  }
}
