import React from "react";
import { NavLink } from "react-router-dom";
import { EditBtn, GroupBtn, StateBtn } from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import { EditCheckbox, EditInput } from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";
import { formatPrice } from '../helpers';

export default class Register extends CRUD {
  constructor(props) {
    super(props);
    this.object = "register";
    this.state = {
      title: "Register",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "account_nr", label: "account_nr" },
        { key: "name", label: "name" },
        {
          key: "main_register",
          label: "Ak",
          render: (v) => (v ? "Yes" : "No"),
        },

        {
          key: "trx_total",
          label: "Total",
          sum: true,
          render: formatPrice,
          numeric: true,
        },
        {
          key: "id",
          label: "List transactions",
          render: (v) => (
            <NavLink to={"/register/" + v + "/trx"}>List transactions</NavLink>
          ),
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="name" />
        <EditInput name="account_nr" />
        <EditCheckbox name="main_register" />
      </FormDialog>
    );
  };
}
