import { Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import { Download } from "../api";
import {
  BookBtn, ExcelBtn,
  GenerateBtn, GroupBtn,
  PrintBtn
} from "../components/CRUD/btn";
import Crud from "../components/CRUD/crud";
import { EditCheckbox, EditInput, EditSelect } from "../components/CRUD/fields";
import { FormInline } from "../components/CRUD/form";
import Info from "../components/CRUD/info";
import CrudToolbar from "../components/CRUD/toolbar";
import Lang from "../components/Lang/lang";
import { formatPrice } from '../helpers';

const objects = ["sale", "purchase", "expense", "assets"];
const titles = ["Sale book", "Purchase book", "Expense book", "Assets book"]

export default class Books extends Crud {
  constructor(props) {
    super(props);
    this.state = this.changeTab(0);
  }

  changeTab = (idx) => {
    if (idx === 0) {
      this.object = objects[idx];
      return {
        tabIdx: idx,
        params: {
          year: new Date().getFullYear(),
          month: new Date().getMonth(),
        },
        title: titles[idx],
        group_by_day: false,
        data: [],
        columns: [
          { key: "invoice_nr", label: "InvoiceNr", numeric: true },
          { key: "issued_on", label: "IssuedOn" },
          { key: "client", label: "Client" },
          { key: "nrf", label: "NRF" },
          { key: "nrb", label: "NRB" },
          { key: "nrt", label: "NRT" },
          {
            key: "9",
            label: "[9]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "10a",
            label: "[10a]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "10c",
            label: "[10c]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "10a+10b+10c",
            label: "[10a+10b+10c]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "11",
            label: "[11]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "12",
            label: "[12]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "K1",
            label: "[K1]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "14",
            label: "[14]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "K2",
            label: "[K2]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "K1+K2",
            label: "[K1+K2]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
        ],
      };
    } else {
      this.object = objects[idx];
      return {
        tabIdx: idx,
        params: {
          year: new Date().getFullYear(),
          month: new Date().getMonth(),
        },
        title: titles[idx],
        data: [],
        columns: [
          { key: "invoice_nr", label: "InvoiceNr" },
          { key: "issued_on", label: "IssuedOn" },
          { key: "supplier", label: "Supplier" },
          { key: "nrf", label: "NRF" },
          { key: "nrb", label: "NRB" },
          { key: "nrt", label: "NRT" },
          {
            key: "31",
            label: "[9]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "43",
            label: "[10a]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "K1",
            label: "[K1]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "45",
            label: "[14]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "K2",
            label: "[K2]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
          {
            key: "K1+K2",
            label: "[K1+K2]",
            sum: true,
            numeric: true,
            render: formatPrice,
          },
        ],
      };
    }
  };

  generate = () => {
    this.setState({ data: null });
    this.query({
      book: {
        [this.object]: {
          fields: this.state.columns.map((c) => c.key),
          group_by: this.state.group_by_day ? ["day"] : [],
          year: this.state.params.year,
          month: this.state.params.month,
        },
      },
    }).then((data) => {
      this.setState({ data: data.book[this.object] });
    });
  };

  download = () => {
    Download("/d", {
      book: {
        [this.object]: {
          fields: this.state.columns.map((c) => c.key),
          group_by: this.state.group_by_day ? ["day"] : [],
          year: this.state.params.year,
          month: this.state.params.month,
        },
      },
    });
  };

  toolbar = () => (
    <CrudToolbar
      leftForm={
        <FormInline>
          <EditSelect
            name="month"
            data={this.state.params}
            options={[
              [0, "All Year"],
              [1, "January"],
              [2, "February"],
              [3, "March	"],
              [4, "April"],
              [5, "May"],
              [6, "June"],
              [7, "July"],
              [8, "August"],
              [9, "September"],
              [10, "October"],
              [11, "November"],
              [12, "December"],
            ]}
          />
          <EditInput name="year" type="number" data={this.state.params} />
          <EditCheckbox
            name="group_by_day"
            data={{ group_by_day: this.state.group_by_day }}
            onChange={(val) => {
              let tab = this.changeTab(this.state.tabIdx);
              if (val) {
                tab.columns[0].key = "invoices";
                // let filter = [
                //   "invoice_nr",
                //   "client",
                //   "supplier",
                //   "nrf/nrb/nrt",
                // ];
                // tab.columns = tab.columns.filter(
                //   (c) => filter.indexOf(c.key) === -1
                // );
              } else {
                tab.columns[0].key = "invoice_nr";
              }
              tab.group_by_day = val;
              this.setState({ data: [] }, () => {
                this.setState(tab);
              });
            }}
          />
          <EditCheckbox
            name="remove_empty_sum"
            data={{ remove_empty_sum: this.state.remove_empty_sum }}
            onChange={(val) => {
              this.setState(old => ({...old, remove_empty_sum: val}));
            }}
          />
          <GenerateBtn onClick={this.generate} />
          <BookBtn onClick={this.download}/>
        </FormInline>
      }
      tabs={
        <Tabs
          value={this.state.tabIdx}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, idx) => {
            this.setState({ data: [] }, () => {
              this.setState(this.changeTab(idx));
            });
          }}
        >
          <Tab
            label={
              <Typography>
                <Lang>SALE</Lang>
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                <Lang>PURCHASE</Lang>
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                <Lang>EXPENSE</Lang>
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                <Lang>ASSETS</Lang>
              </Typography>
            }
          />
        </Tabs>
      }
      left={<Info title={this.state.title} />}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} removeEmptySum={this.state.remove_empty_sum} />
        </GroupBtn>
      }
    />
  );

  componentDidMount() {
    this.setState({ data: [] });
  }
}
