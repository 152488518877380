import { Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import {
  ExcelBtn,
  GenerateBtn, GroupBtn,
  PrintBtn
} from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import {
  EditDateTime,

  EditSelectAutocomplete
} from "../components/CRUD/fields";
import { FormInline } from "../components/CRUD/form";
import Info from "../components/CRUD/info";
import CrudToolbar from "../components/CRUD/toolbar";
import Lang from "../components/Lang/lang";
import { formatDateTime, formatPrice } from "../helpers";

export default class History extends CRUD {
  constructor(props) {
    super(props);
    this.object = "report";
    this.state = this.changeTab(0);
  }

  changeTab = (idx) => {
    if (idx === 0) {
      return {
        title: "SaleHistory",
        tabIdx: idx,
        data: [],
        columns: [
          { key: "invoice_nr", label: "Invoice Nr." },
          { key: "user", label: "User" },
          { key: "client", label: "Client" },
          { key: "table", label: "Table" },
          { key: "created_on", label: "Created On", render: formatDateTime },
          { key: "issued_on", label: "Issued On", render: formatDateTime },
          { key: "article", label: "Article" },
          { key: "options", label: "Options" },
          {
            key: "amount",
            label: "Quantity",
            render: (v) => v.toFixed(2),
            numeric: true,
            sum: true,
          },
          {
            key: "price",
            label: "Price",
            numeric: true,
            render: formatPrice,
          },
          {
            key: "total",
            label: "Total",
            numeric: true,
            sum: true,
            render: formatPrice,
          },
        ],
        params: { from: new Date(), to: new Date(), item: "" },
        item_id: 0,
        item: this.state.item || [],
      };
    } else {
      return {
        title: "PurchaseHistory",
        tabIdx: idx,
        data: [],
        columns: [
          { key: "invoice_nr", label: "Invoice Nr." },
          { key: "supplier", label: "Supplier" },
          { key: "issued_on", label: "Issued On", render: formatDateTime },
          { key: "article", label: "Article" },
          {
            key: "amount",
            label: "Qty",
            render: (v) => v.toFixed(2),
            numeric: true,
            sum: true,
          },
          {
            key: "price",
            label: "Price",
            numeric: true,
            render: formatPrice,
          },
          {
            key: "total",
            label: "Total",
            numeric: true,
            sum: true,
            render: formatPrice,
          },
        ],
        params: { from: new Date(), to: new Date(), item: "" },
        item_id: 0,
        item: this.state.item || [],
      };
    }
  };

  generate = () => {
    this.setState({ data: null });

    let nParams = this.state.params;
    if (this.state.tabIdx === 0) {
      nParams.item = this.state.item.find(
        (i) => i[0] === this.state.item_id
      )[1];
      this.setState({ data: null, params: nParams });

      let where = [
        "issued_on > " + this.state.params.from.toISOString(),
        "issued_on < " + this.state.params.to.toISOString(),
        "item_id = " + this.state.item_id,
      ];

      this.query({
        [this.object]: {
          SaleHistory: {
            fields: this.state.columns.map((c) => c.key),
            where: where,
          },
        },
      }).then((data) => {
        this.setState({ data: data[this.object].SaleHistory });
      });
    } else {
      nParams.item = this.state.item.find(
        (i) => i[0] === this.state.item_id
      )[1];
      this.setState({ data: null, params: nParams });

      let where = [
        "issued_on > " + this.state.params.from.toISOString(),
        "issued_on < " + this.state.params.to.toISOString(),
        "item_id = " + this.state.item_id,
      ];

      this.query({
        [this.object]: {
          PurchaseHistory: {
            fields: this.state.columns.map((c) => c.key),
            where: where,
          },
        },
      }).then((data) => {
        this.setState({ data: data[this.object].PurchaseHistory });
      });
    }
  };

  toolbar = () => (
    <CrudToolbar
      leftForm={
        <FormInline>
          <EditDateTime name="from" data={this.state.params} />
          <EditDateTime name="to" data={this.state.params} />
          <EditSelectAutocomplete
            name={"item_id"}
            data={this.state}
            options={this.state.item.filter((it) =>
              this.state.tabIdx === 0 ? it[3] !== 0 : it[2] === 0
            )}
            style={{ minWidth: "150px" }}
          />
          <GenerateBtn onClick={this.generate} />
        </FormInline>
      }
      tabs={
        <Tabs
          value={this.state.tabIdx}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, idx) => {
            this.setState({ data: [] }, () => {
              this.setState(this.changeTab(idx));
            });
          }}
        >
          <Tab
            label={
              <Typography>
                <Lang>SALE</Lang>
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                <Lang>PURCHASE</Lang>
              </Typography>
            }
          />
        </Tabs>
      }
      left={<Info title={this.state.title} />}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
        </GroupBtn>
      }
    />
  );

  componentDidMount() {
    this.setState({ data: [] });
    this.query({
      item: {
        read: {
          fields: ["id", "name", "type", "menu_id"],
          where: ["state = 1"],
        },
      },
    }).then((data) => {
      this.setState({
        item: data.item.read,
      });
    });
  }
}
