import React from 'react';
import CRUD from '../components/CRUD/crud';
import { GroupBtn, EditBtn, StateBtn } from '../components/CRUD/btn';
import { EditInput } from '../components/CRUD/fields';
import FormDialog from '../components/CRUD/form';
import { NavLink } from 'react-router-dom';

export default class KontoSubCategory extends CRUD {
  constructor(props) {
    super(props);
    this.object = 'konto_sub_category';
    this.state = {
      title: "Konto sub categories",
      columns: [
        {key: "id", label: "ID", numeric: true},
        {key: "name", label: "Name"},
        {key: "id", label: "List",  render: (v) => 
          <NavLink to={"/konto/" + v}>List Kontos</NavLink>},
        {key: "state", label: "Action", render: (v, row) => (
          <GroupBtn>
            <EditBtn onClick={() => this.onEdit(row)}/>
            <StateBtn state={v} onClick={(v) => this.onSaveForm(row, {state: v})}/>
          </GroupBtn>
        )},
      ]
    }
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    obj['konto_category_id'] = this.props.match.params.id;
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name='name'/>
      </FormDialog>
    );
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.query({
      [this.object]: { 
        read: { 
          fields: this.state.columns.map(c => c.key),
          where: ["konto_category_id = " + id]
        },
      },
      konto_category: {
        read: {
          fields: ["name"],
          where: ["id = " + id]
        }
      }
    }).then(data => {
      let m = data.konto_category.read[0];
      this.setState({
        params: { category: m[0] },
        data: data[this.object].read
      });
    })
  }
}