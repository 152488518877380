import React from "react";
import CRUD from "../components/CRUD/crud";
import { GroupBtn, EditBtn, StateBtn } from "../components/CRUD/btn";
import { EditInput, EditSelect, EditDate } from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";
import { NavLink } from "react-router-dom";
import { formatDate } from "../helpers";

export default class Transfer extends CRUD {
  constructor(props) {
    super(props);
    this.object = "transfer"; // server side object name
    this.state = {
      title: "Transfer",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "from", label: "From", render: (v) => this.state.branches[v] },
        { key: "to", label: "To", render: (v) => this.state.branches[v] },
        { key: "issued_on", label: "IssuedOn", render: formatDate },
        { key: "note", label: "Note" },
        {
          key: "id",
          label: "List items",
          render: (v) => <NavLink to={"/transfer/" + v}>List</NavLink>,
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
      branches: {},
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    return (
      <FormDialog crud={this} data={obj}>
        <EditSelect name="from_branch_id" options={data.branch.read} />
        <EditSelect name="to_branch_id" options={data.branch.read} />
        <EditDate name="issued_on" />
        <EditInput name="note" />
      </FormDialog>
    );
  };

  onRowAdded = (row) => {
    this.props.history.push("/transfer/" + row[0]);
  };

  onEdit = (row) => {
    let req = {
      branch: {
        read: { fields: ["id", "name"] },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data });
    });
  };

  componentDidMount() {
    this.query({
      [this.object]: { read: { fields: this.state.columns.map((c) => c.key) } },
      branch: {
        read: { fields: ["id", "name"] },
      },
    }).then((data) => {
      let branches = {};
      data.branch.read.forEach((b) => {
        branches[b[0]] = b[1];
      });
      this.setState({ data: data[this.object].read, branches: branches });
    });
  }
}
