import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import { CircularProgress } from "@material-ui/core";
import { setHistory } from "../api";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  background: {
    backgroundColor: "#eaeef3"
  },
  color: {
    color: "white",
    padding: "5px"
  }
}));

const Loading = ({ history }) => {
  setHistory(history);
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, classes.background)}>
      <CircularProgress className={classes.color} />
      <Typography className={classes.color}>Loading...</Typography>
    </div>
  );
};

export default Loading;
