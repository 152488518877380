// Icons
import React from "react";

import HomeIcon from "@material-ui/icons/Home";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AppsIcon from "@material-ui/icons/Apps";
import CategoryIcon from "@material-ui/icons/Category";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MoneyIcon from "@material-ui/icons/Money";
import EmojiTransportation from "@material-ui/icons/EmojiTransportation";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import StoreIcon from "@material-ui/icons/Store";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PieChartIcon from "@material-ui/icons/PieChart";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import HouseIcon from "@material-ui/icons/House";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Dashboard from "./dashboard/dashboard";
import User from "./user/user";
import Client from "./client/client";
import Option from "./option/option";
import Menu from "./menu/menu";
import Konto from "./konto/konto";
import Supplier from "./supplier/supplier";
import KontoCategory from "./konto/category";
import TableCategory from "./table/category";
import Table from "./table/table";

import SaleReport from "./report/sale";
import SaleRepoInvoice from "./report/sale_invoice";
import StockReport from "./report/stock";
import TopReport from "./report/top";
import HistoryReport from "./report/history";

// import Department from "./department/department";
import Item from "./menu/item";
import item2 from "./item/item";
import Item3 from "./item/item2";
import CashRegister from "./cashregister/cashregister";
import Purchase from "./purchase/purchase";
import Expense from "./expense/expense";
import ExpenseItem from "./expense/item";
import MenuCategory from "./menu/category";
import Ingredient from "./menu/ingredient";
import KontoSubCategory from "./konto/sub";
import PurchaseItem from "./purchase/item";
import AssetItem from "./assets/item";
import CashRegisterItems from "./cashregister/item";
import Books from "./finance/books";
import Transfer from "./transfer/transfer";
import TrasnferItem from "./transfer/item";
import DeleteReport from "./report/delete";
import TableReport from "./report/table";
// import IncomeStatement from "./finance/income_statement";
// import BalanceSheet from "./finance/balance_sheet";
import SupplierItem from "./supplier/item";
import SupplierPurchase from "./supplier/purchase";
// import Assets from "./assets/assets";
import PurchaseTrx from "./purchase/trx";
import ExpenseTrx from "./expense/trx";
import Register from "./register/register";
import RegisterTrx from "./register/trx";
import Profile from "./profile/profile";
import DeliveryCost from "./deliverycost/delivery_cost";
import Assets from "./assets/assets";
import AssetsTrx from "./assets/trx";
import PurchaseOver500 from "./report/purchase_over500";
import AnalyticsReport from "./report/analytics";
import Invoice from "./invoice/invoice";
import InvoiceItem from "./invoice/item";
import InvoiceTrx from "./invoice/trx";
import IncomeStatement from "./finance/income_statement";

export default {
  items: [
    { name: "ADMIN", type: "category" },
    {
      path: "/",
      name: "Home",
      type: "link",
      icon: HomeIcon,
      component: Dashboard,
    },
    // {
    //   path: "/item2",
    //   name: "Item2",
    //   type: "link",
    //   icon: AccountCircleIcon,
    //   component: Item3
    // },
    {
      path: "/user",
      name: "users",
      type: "link",
      icon: PersonAddIcon,
      component: User,
    },
    { name: "DELIVERY", type: "category" },
    {
      path: "/deliverycost",
      name: "Delivery Cost",
      type: "link",
      icon: EmojiTransportation,
      component: DeliveryCost,
    },
    { name: "HR", type: "category" },
    // {
    //   path: '/department',
    //   name: 'Departments',
    //   type: 'link',
    //   icon: GroupAddIcon,
    //   component: Department
    // },
    {
      path: "/cashregister",
      name: "Cash Register",
      type: "link",
      icon: MoneyIcon,
      component: CashRegister,
      children: [
        {
          path: "/cashregister/:id",
          component: CashRegisterItems,
        },
      ],
    },
    { name: "POS", type: "category" },
    {
      path: "/table",
      name: "Tables",
      type: "link",
      icon: AppsIcon,
      component: TableCategory,
      children: [
        {
          path: "/table/:id",
          name: "Tables",
          component: Table,
        },
      ],
    },
    {
      path: "/item",
      name: "Articles",
      type: "link",
      icon: FiberManualRecordIcon,
      component: item2,
    },
    {
      path: "/category",
      name: "Menus",
      breadcrumb: "Menu Category",
      type: "link",
      icon: CategoryIcon,
      component: MenuCategory,
      children: [
        {
          path: "/menu/:id",
          breadcrumb: "Sub Category",
          component: Menu,
        },
        {
          path: "/item/:id",
          breadcrumb: "Item",
          component: Item,
        },
        {
          path: "/ingredient/:id",
          breadcrumb: "Ingredient",
          component: Ingredient,
        },
      ],
    },
    {
      path: "/option",
      name: "Options",
      type: "link",
      icon: CheckBoxIcon,
      component: Option,
    },
    {
      path: "/client",
      name: "Clients",
      type: "link",
      icon: AccountCircleIcon,
      component: Client,
    },
    {
      path: "/report",
      name: "Reports",
      type: "submenu",
      icon: PieChartIcon,
      children: [
        {
          path: "/sale",
          name: "Sale",
          component: SaleReport,
        },
        {
          path: "/saleinvoice",
          name: "Sale Invoice",
          component: SaleRepoInvoice,
        },
        {
          path: "/purchaseover500",
          name: "Purchase Over 500",
          component: PurchaseOver500,
        },
        {
          path: "/stock",
          name: "Stock",
          component: StockReport,
        },
        {
          path: "/table",
          name: "Table",
          component: TableReport,
        },
        {
          path: "/history",
          name: "History",
          component: HistoryReport,
        },
        {
          path: "/analytics",
          name: "Analytics",
          component: AnalyticsReport,
        },
        {
          path: "/top",
          name: "Top",
          component: TopReport,
        },
        {
          path: "/delete",
          name: "Delete",
          component: DeleteReport,
        },
      ],
    },
    { name: "TRANSAKSIONET", type: "category" },
    {
      path: "/register",
      name: "Register",
      type: "link",
      icon: AccountBalanceIcon,
      component: Register,
      children: [
        {
          path: "/register/:id/trx",
          component: RegisterTrx,
        },
      ],
    },
    { name: "BLERJET/SHPENZIMET", type: "category" },
    {
      path: "/transfer",
      name: "Transfer",
      type: "link",
      icon: TransferWithinAStationIcon,
      component: Transfer,
      children: [
        {
          path: "/transfer/:id",
          breadcrumb: "Transfer Items",
          component: TrasnferItem,
        },
      ],
    },
    {
      path: "/kontocategory",
      name: "Konto",
      type: "link",
      icon: CreditCardIcon,
      component: KontoCategory,
      children: [
        {
          path: "/kontosub/:id",
          component: KontoSubCategory,
        },
        {
          path: "/konto/:id",
          component: Konto,
        },
      ],
    },
    {
      path: "/supplier",
      name: "Suppliers",
      type: "link",
      icon: LocalShippingIcon,
      component: Supplier,
      children: [
        {
          path: "/supplier/:id",
          component: SupplierItem,
        },
        {
          path: "/supplier/purchase/:id",
          component: SupplierPurchase,
        },
      ],
    },
    {
      path: "/invoice",
      name: "Invoice",
      type: "link",
      icon: AttachMoneyIcon,
      component: Invoice,
      children: [
        {
          path: "/invoice/:id",
          component: InvoiceItem,
        },
        {
          path: "/invoice/:id/trx",
          component: InvoiceTrx,
        },
      ],
    },
    {
      path: "/purchase",
      name: "Purchase",
      type: "link",
      icon: StoreIcon,
      component: Purchase,
      children: [
        {
          path: "/purchase/:id",
          component: PurchaseItem,
        },
        {
          path: "/purchase/:id/trx",
          component: PurchaseTrx,
        },
      ],
    },
    {
      path: "/expense",
      name: "Expenses",
      type: "link",
      icon: AssignmentIcon,
      component: Expense,
      children: [
        {
          path: "/expense/:id",
          component: ExpenseItem,
        },
        {
          path: "/expense/:id/trx",
          component: ExpenseTrx,
        },
      ],
    },
    {
      path: "/assets",
      name: "Assets",
      type: "link",
      icon: HouseIcon,
      component: Assets,
      children: [
        {
          path: "/assets/:id",
          component: AssetItem,
        },
        {
          path: "/assets/:id/trx",
          component: AssetsTrx,
        },
      ],
    },
    /*
    {
      path: '/assets',
      name: 'Assets',
      type: 'link',
      icon: DriveEtaIcon,
      component: Assets,
    },
    */
    { name: "FINANCE", type: "category" },
    {
      path: "/books",
      name: "Books",
      type: "link",
      icon: LibraryBooksIcon,
      component: Books,
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: "/income-statement",
      name: "Income Statement",
      type: "link",
      icon: LibraryBooksIcon,
      component: IncomeStatement,
    },
    // {
    //   path: "/balance-sheet",
    //   name: "Balance Sheet",
    //   type: "link",
    //   icon: LibraryBooksIcon,
    //   component: BalanceSheet,
    // },
  ],
};
