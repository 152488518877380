import React from "react";
import { EditBtn, GroupBtn, StateBtn } from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import {
  EditInput,
  EditSelect,
  EditSelectAutocomplete
} from "../components/CRUD/fields";
import FormDialog, { FormDialog2 } from "../components/CRUD/form";
import { formatDate, formatPrice } from "../helpers";

export default class PurchaseItem extends CRUD {
  constructor(props) {
    super(props);
    this.object = "purchase_item"; // server side object name
    this.state = {
      title: "Purchase Items",
      addItem: false,
      addedItemId: 0,
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Article" },
        {
          key: "amount",
          label: "Qty",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
        { key: "price", label: "Price", render: formatPrice, numeric: true },
        { key: "total", label: "Total", render: formatPrice, numeric: true },
        {
          key: "rabat",
          label: "Rabat",
          render: (v) => v.toFixed(0) + "%",
          numeric: true,
        },
        {
          key: "vat",
          label: "Vat",
          render: (v) => v.toFixed(2) + "%",
          numeric: true,
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    if (this.state.addItem) {
      let obj = {
        menu_id: 0,
        type: 0,
        branch: "18446744073709551615",
      };
      return (
        <FormDialog2
          data={obj}
          object="item"
          onSave={(response) => {
            this.setState(
              (state) => ({
                ...state,
                addItem: false,
                addedItemId: response,
              }),
              () => this.onEdit({})
            );
          }}
          onClose={() => {
            this.setState((state) => ({
              ...state,
              addItem: false,
            }));
          }}
        >
          <EditInput name="barcode" />
          <EditInput name="name" />
          <EditSelect name="menu_id" options={data.menu.read} />
          <EditInput name="price1" type="number" />
          <EditSelect name="unit_id" options={data.unit.read} />
          <EditSelect name="vat_id" options={data.vat.read} />
        </FormDialog2>
      );
    }

    let obj = data[this.object].one;
    if (obj.id && !obj["purchase_id"]) {
      const it = data.item.lastPurchase.find((i) => i[0] === obj.item_id);
      // obj.vat_id = it[2];
      // obj.price = it[3];
      obj.sale_price = it[5];
    } else if (!obj["purchase_id"] && data.item.lastPurchase.length > 0) {
      const it = data.item.lastPurchase[0];
      obj.vat_id = it[2];
      obj.price = it[3];
      obj.rabat = it[4];
      obj.sale_price = it[5];
      obj.konto_id = it[6];
    }
    obj["purchase_id"] = parseInt(this.props.match.params.id, 10);
    if (this.state.addedItemId > 0) {
      obj.item_id = this.state.addedItemId;
    }

    return (
      <FormDialog crud={this} data={obj}>
        <EditSelectAutocomplete
          name="item_id"
          options={data.item.lastPurchase}
          onChange={(value) => {
            const it = data.item.lastPurchase.find((i) => i[0] === value);
            obj.vat_id = it[2];
            obj.price = it[3];
            obj.rabat = it[4];
            obj.sale_price = it[5];
            obj.konto_id = it[6];
            this.setState({ formData: { ...data } });
          }}
          onAdd={() => {
            this.setState((state) => ({
              ...state,
              addItem: true,
            }));
          }}
        />
        <EditInput name="amount" type="number" />
        <EditInput name="price" type="number" />
        <EditInput name="sale_price" type="number" />
        <EditInput name="rabat" type="number" />
        <EditSelectAutocomplete
          name="konto_id"
          options={data.konto.read}
          onChange={(value) => {
            const konto = this.state.formData.konto.read.find(
              (k) => k[0] === value
            );
            if (konto) {
              obj.vat_id = konto.vat_id;
              this.setState({});
            }
          }}
        />
        <EditSelect name="vat_id" options={data.vat.read} />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      item: {
        lastPurchase: {
          fields: [
            "id",
            "barcode",
            "name",
            "vat_id",
            "price",
            "rabat",
            "sale_price",
            "konto_id",
          ],
        },
      },
      konto: {
        read: {
          fields: ["id", "code", "desc", "vat_id"],
          where: ["state != 0", "type = PURCHASE"],
        },
      },
      vat: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      menu: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      unit: {
        read: {
          fields: ["id", "name"],
        },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      data.item.lastPurchase = data.item.lastPurchase.map((it) => [
        it[0],
        it[1] + " [" + it[2] + "]",
        it[3],
        it[4],
        it[5],
        it[6],
        it[7],
      ]);
      data.menu.read = [[0, "-"], ...data.menu.read];
      console.log(data.konto.read);
      data.konto.read = data.konto.read.map((k) => [
        k[0],
        k[1] + " [" + k[2] + "]",
        k[3],
      ]);
      console.log(data);
      this.setState({ showForm: true, formData: data });
    });
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["purchase_id = " + id, "type = PURCHASE"],
        },
      },
      purchase: {
        read: {
          fields: ["invoice_nr", "supplier", "issued_on", "total"],
          where: ["id = " + id],
        },
      },
    }).then((data) => {
      let m = data.purchase.read[0];
      this.setState({
        params: {
          invoice_nr: m[0],
          supplier: m[1],
          issued_on: formatDate(m[2]),
          total: formatPrice(m[3]),
        },
        data: data[this.object].read,
      });
    });
  }
}
