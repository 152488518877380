import React from "react";
import { render } from "react-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import { amber, green } from "@material-ui/core/colors";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { AppProvider } from "./components";
import clsx from "clsx";
import { IconButton } from "@material-ui/core";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  danger: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  danger: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export function CustomAlert(props) {
  const classes = useStyles();
  const Icon = variantIcon[props.tpe];
  console.log(classes[props.tpe]);
  return (
    <SnackbarContent
      className={clsx(classes[props.tpe], props.tpe)}
      message={
        <span className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {props.msg}
        </span>
      }
      action={
        <IconButton color="inherit" onClick={props.onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      }
    />
  );
}

export default function Alert(tpe, message) {
  let tmt = 0;
  const close = () => {
    clearTimeout(tmt);
    render(<AppProvider></AppProvider>, document.getElementById("snackbar"));
  };
  render(
    <AppProvider>
      <CustomAlert tpe={tpe} msg={message} onClose={close} />
    </AppProvider>,
    document.getElementById("snackbar")
  );
  tmt = setTimeout(close, 5000);
}
