import React from 'react';
import CRUD from '../components/CRUD/crud';
import { GroupBtn, EditBtn, StateBtn } from '../components/CRUD/btn';
import { EditInput } from '../components/CRUD/fields';
import FormDialog from '../components/CRUD/form';
import { NavLink } from 'react-router-dom';

export default class KontoCategory extends CRUD {
  constructor(props) {
    super(props);
    this.object = 'konto_category';
    this.state = {
      title: "Konto Categories",
      columns: [
        {key: "id", label: "ID", numeric: true},
        {key: "name", label: "Name"},
        {key: "id", label: "List",  render: (v) => 
          <NavLink to={"/kontosub/" + v}>List sub categories</NavLink>},
        {key: "state", label: "Action", render: (v, row) => (
          <GroupBtn>
            <EditBtn onClick={() => this.onEdit(row)}/>
            <StateBtn state={v} onClick={(v) => this.onSaveForm(row, {state: v})}/>
          </GroupBtn>
        )},
      ]
    }
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name='name'/>
      </FormDialog>
    );
  }
}