import React from "react";
import CRUD from "../components/CRUD/crud";
import { GroupBtn, EditBtn, StateBtn } from "../components/CRUD/btn";
import { EditInput, EditSelect } from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";

export default class Konto extends CRUD {
  constructor(props) {
    super(props);
    this.object = "konto"; // server side object name
    this.state = {
      title: "Kontos",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "code", label: "Code", numeric: true }, //key as in db name
        { key: "desc", label: "Desc" },
        {
          key: "vat",
          label: "Vat",
          render: (v) => v.toFixed(2) + "%",
          numeric: true,
        },
        { key: "type", label: "Type" },
        {
          key: "devaluation",
          label: "Devaluation",
          render: (v) => v.toFixed(2) + "%",
          numeric: true,
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    obj["konto_sub_category_id"] = this.props.match.params.id;
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="code" type="number" />
        <EditInput name="desc" />
        <EditSelect name="vat_id" options={data.vat.read} />
        <EditSelect
          name="type"
          options={[
            ["PURCHASE", "PURCHASE"],
            ["EXPENSE", "EXPENSE"],
            ["ASSETS", "ASSETS"],
          ]}
        />
        <EditInput name="devaluation" type="number" />
        <EditSelect
          name="konto_sub_category_id"
          options={data.konto_sub_category.read}
        />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      vat: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      konto_sub_category: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data });
    });
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["konto_sub_category_id = " + id, "state = 1"],
        },
      },
      konto_sub_category: {
        read: {
          fields: ["name"],
          where: ["id = " + id],
        },
      },
    }).then((data) => {
      let m = data.konto_sub_category.read[0];
      this.setState({
        params: { sub_category: m[0] },
        data: data[this.object].read,
      });
    });
  }
}
