import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { CircularProgress, Typography } from "@material-ui/core";
import compare from "./compare";
import Lang from "../Lang/lang";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto",
    maxHeight: 730,
    overflow: "auto"
    // paddingTop: theme.spacing(1),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0
  },
  cell: {
    paddingTop: 2,
    paddingBottom: 0
  }
}));

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props.cells.map((headCell, idx) => (
          <TableCell
            key={idx}
            sortDirection={props.orderBy === idx ? props.order : false}
            align={headCell.numeric ? "right" : "left"}
          >
            <TableSortLabel
              active={props.orderBy === idx}
              direction={props.order}
              onClick={() => props.onSort(idx)}
            >
              {headCell.sum
                ? "[ " +
                  (headCell.render || (v => v))(
                    props.rows.reduce((acc, curr) => acc + curr[idx], 0)
                  ) +
                  " ]"
                : null}{" "}
              <Lang>{headCell.label || headCell.key}</Lang>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default function CRUDTable(props) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState(props.data);

  const columns = props.columns;
  const rowsPerPage = 22;

  const renderRows = data => {
    let ret = [];
    for (let i = page * rowsPerPage; i < (page + 1) * rowsPerPage; ++i) {
      if (!data || data.length <= i) {
        break;
      }
      let row = data[i];
      ret.push(
        <TableRow hover tabIndex={-1} key={row[props.keyIdx]}>
          {row.map((c, i) => (
            <TableCell
              key={i}
              className={classes.cell}
              align={columns[i].numeric ? "right" : "left"}
            >
              {columns[i].render ? columns[i].render(c, row) : c}
            </TableCell>
          ))}
        </TableRow>
      );
    }

    return ret;
  };

  const handleRequestSort = (property, dontToogle) => {
    const stateIdx = columns.findIndex(c => c.key === "state");
    let data = props.data;
    if ((data || []).length > 0) {
      let isDesc = orderBy === property && order === "desc";
      if (!dontToogle) {
        setOrder(isDesc ? "asc" : "desc");
      } else {
        isDesc = !isDesc;
      }
      setOrderBy(property);
      let _filters = {};

      if (stateIdx === property) {
        _filters.disabled = row => row[stateIdx] > 0 !== isDesc;
      } else if (stateIdx > -1) {
        _filters.disabled = row => row[stateIdx] > 0;
      }

      if (props.keyword) {
        _filters.search = row => {
          for (let i = 0; i < row.length; i++) {
            if (("" + row[i]).toLowerCase().indexOf(props.keyword) > -1) {
              return true;
            }
          }
          return false;
        };
      }

      data = doFilter(data, _filters);
      let cmp = compare(data[0], property, !isDesc);
      data.sort(cmp);
    }
    setData(data);
  };

  const doFilter = (raw, filters) => {
    raw = raw || [];
    const arrFilters = Object.values(filters || {});
    return raw.filter(row => {
      for (let i = 0; i < arrFilters.length; i++) {
        if (!arrFilters[i](row)) {
          return false;
        }
      }
      return true;
    });
  };

  useEffect(() => {
    handleRequestSort(orderBy, true);
  }, [props.data, props.keyword]);

  const classes = useStyles();
  return (
    <div>
      <div className={classes.tableWrapper}>
        <Table stickyHeader className={classes.table} size={"small"}>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            cells={columns || []}
            rowCount={(data || []).length}
            rows={data || []}
            onSort={handleRequestSort}
          />
          <TableBody>{renderRows(data)}</TableBody>
        </Table>
      </div>
      {data == null ? (
        <div style={{ padding: 10, textAlign: "center" }}>
          <CircularProgress />
          <Typography>Loading...</Typography>
        </div>
      ) : null}
      <TablePagination
        component="div"
        count={(data || []).length}
        rowsPerPageOptions={[rowsPerPage]}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_, page) => setPage(page)}
        backIconButtonProps={{ "aria-label": "previous page" }}
        nextIconButtonProps={{ "aria-label": "next page" }}
      />
    </div>
  );
}
