import React from "react";
import CRUD from "../components/CRUD/crud";
import CrudToolbar from "../components/CRUD/toolbar";
import Info from "../components/CRUD/info";
import {
  GroupBtn,
  PrintBtn,
  ExcelBtn,
  GenerateBtn,
} from "../components/CRUD/btn";
import { EditDateTime, EditSelect } from "../components/CRUD/fields";
import { FormInline } from "../components/CRUD/form";
import { formatPrice } from "../helpers";

export default class Table extends CRUD {
  constructor(props) {
    super(props);
    this.object = "report";
    this.state = {
      title: "Table",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Name" },
        { key: "category", label: "Category" },
        {
          key: "total",
          label: "Total",
          sum: true,
          numeric: true,
          render: formatPrice,
        },
      ],
      params: { from: new Date(), to: new Date(), table: "ALL" },
      tables: [],
      table_id: 0,
    };
  }

  generate = () => {
    this.setState({ data: null });
    let where = [
      "issued_on > " + this.state.params.from.toISOString(),
      "issued_on < " + this.state.params.to.toISOString(),
    ];
    if (this.state.table_id > 0) {
      where.push("id = " + this.state.table_id);
    }
    this.query({
      [this.object]: {
        Table: {
          fields: this.state.columns.map((c) => c.key),
          where: where,
        },
      },
    }).then((data) => {
      this.setState({ data: data[this.object].Table });
    });
  };

  toolbar = () => (
    <CrudToolbar
      leftForm={
        <FormInline>
          <EditDateTime name="from" data={this.state.params} />
          <EditDateTime name="to" data={this.state.params} />
          <EditSelect
            name="table_id"
            data={this.state}
            options={this.state.tables}
          />
          <GenerateBtn onClick={this.generate} />
        </FormInline>
      }
      left={<Info title={this.state.title} />}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
        </GroupBtn>
      }
    />
  );

  componentDidMount() {
    this.query({
      table: { read: { fields: ["id", "name"] } },
    }).then((data) => {
      this.setState({
        tables: [[0, "ALL"]].concat(data.table.read),
      });
    });
    this.setState({ data: [] });
  }
}
