import React from "react";
import { useContext } from "react";
import { LangCtx } from "../../index";

let l = {
  name: "Name",
  contact: "Contact",
  color: "Color",
  perc: "Perc",
  acion: "Action",
  type_id: "Type",
};

export default function Lang(props) {
  const translator = useLang();
  // const key = props.children || "";
  // let lkey = key;
  // try {
  //   lkey = key.toLowerCase();
  // } catch (ex) {}
  // const lang = useContext(LangCtx);
  // if (l[lkey] && l[lkey][lang]) {
  //   return l[lkey][lang];
  // }
  return translator(props.children || "");
}

export const useLang = () => {
  const lang = useContext(LangCtx);
  return (key) => {
    let lkey = key;
    try {
      lkey = key.toLowerCase();
    } catch (ex) {}
    if (l[lkey] && l[lkey][lang]) {
      return l[lkey][lang];
    }
    return key;
  };
};

export const SetLang = (lang) => {
  l = lang;
};
