import { format } from "date-fns";
import { Currency } from ".";

// Capitalize
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Format price
export function formatPrice(number) {
  return number.toFixed(2) + " " + Currency;
}

export function formatDate(date) {
  return format(new Date(date), "dd.MM.yyyy");
}

export function formatDateTime(date) {
  return format(new Date(date), "HH:mm dd.MM.yyyy");
}
