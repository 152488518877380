import React from "react";
import CRUD from "../components/CRUD/crud";
import { GroupBtn, EditBtn, StateBtn, MoveBtn } from "../components/CRUD/btn";
import { EditInput, EditSelect, EditBranch } from "../components/CRUD/fields";
import FormDialog, { FormDialogBody } from "../components/CRUD/form";
import { NavLink } from "react-router-dom";
import Lang from "../components/Lang/lang";

export default class Menu extends CRUD {
  constructor(props) {
    super(props);
    this.object = "menu";
    this.key = 1;
    this.state = {
      title: "Sub categories",
      columns: [
        {
          key: "pos",
          label: "Order",
          numeric: true,
          render: (v, row) => {
            return (
              <MoveBtn
                onClick={() => {
                  if (v === this.state.moving) {
                    this.setState({ moving: 0, movingPos: 0 });
                  } else if (this.state.moving > 0) {
                    this.onSwap(
                      this.state.moving,
                      this.state.movingPos,
                      row[this.key],
                      v
                    );
                    this.setState({ moving: 0, movingPos: 0 });
                  } else {
                    this.setState({ moving: row[this.key], movingPos: v });
                  }
                }}
                id={row[this.key]}
                moving={this.state.moving}
              />
            );
          },
        },
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "name" },
        { key: "printer", label: "printer" },
        {
          key: "id",
          label: "List articles",
          render: (v) => (
            <NavLink to={"/item/" + v}>
              <Lang>List articles</Lang>
            </NavLink>
          ),
        },
        {
          key: "img",
          label: "Image",
          render: (v, row) => (
            <button
              onClick={() => {
                this.setState({
                  image: true,
                  showForm: true,
                  formData: { [this.object]: {}, row: row, img: v },
                });
              }}
            >
              Image
            </button>
          ),
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  onSwap = (id, pos, withId, withPos) => {
    this.query({
      [this.object]: {
        swap: {
          id: id,
          pos: pos,
          with_id: withId,
          with_pos: withPos,
          fields: this.state.columns.map((c) => c.key),
        },
      },
    }).then((data) => {
      if (data[this.object].swap) {
        this.swapRows(data[this.object].swap);
      }
    });
  };

  createForm = (data) => {
    if (this.state.image) {
      return (
        <FormDialogBody
          crud={this}
          title="Image"
          onSave={() => {
            if (this.state.img) {
              const formData = new FormData();
              formData.append("img", this.state.img);

              fetch("/u?t=menu&id=" + data.row[this.key], {
                method: "POST",
                body: formData,
              })
                .then((response) => {
                  response.text().then((file) => {
                    data.row[
                      this.state.columns.findIndex((c) => c.key === "img")
                    ] = file;
                    console.log(file);
                    this.setState({
                      img: undefined,
                      showForm: false,
                      formData: {},
                    });
                  });
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              this.setState({
                img: undefined,
                showForm: false,
                formData: {},
              });
            }
          }}
        >
          <>
            <img src={"/static/images/" + data.img} height="200" />
            <input
              type="file"
              name="img"
              accept="image/png"
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  this.setState({ img: e.target.files[0] });
                }
              }}
            />
          </>
        </FormDialogBody>
      );
    }
    let obj = data[this.object].one;
    obj["menu_category_id"] = parseInt(this.props.match.params.id, 10);
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="name" />
        <EditSelect name="menu_category_id" options={data.menu_category.read} />
        <EditSelect name="printer_id" options={data.printer.read} />
        <EditBranch name="branch" branches={data.branch.read} />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      menu_category: { read: { fields: ["id", "name"] } },
      branch: { read: { fields: ["id", "name"] } },
      printer: { read: { fields: ["id", "name"] } },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      console.log(data.printer);
      this.setState({ showForm: true, formData: data, image: false });
    });
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["menu_category_id = " + id],
        },
      },
      menu_category: {
        read: {
          fields: ["name"],
          where: ["id = " + id],
        },
      },
    }).then((data) => {
      let m = data.menu_category.read[0];
      this.setState({
        params: { category: m[0] },
        data: data[this.object].read,
      });
    });
  }
}
