import { Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import {
  ExcelBtn,
  GenerateBtn, GroupBtn,
  PrintBtn
} from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import { EditDateTime, EditSelect } from "../components/CRUD/fields";
import { FormInline } from "../components/CRUD/form";
import Info from "../components/CRUD/info";
import CrudToolbar from "../components/CRUD/toolbar";
import Lang from "../components/Lang/lang";

export default class Stock extends CRUD {
  constructor(props) {
    super(props);
    this.object = "report";
    this.state = {
      title: "Sale totals",
      tabIdx: 0,
      keyword: "",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Name" },
        {
          key: "prestock",
          label: "PreStock",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
        {
          key: "sold",
          label: "Sold",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
        {
          key: "purchased",
          label: "Purchased",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
        {
          key: "stock",
          label: "Stock",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
        {
          key: "price",
          label: "price",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
        {
          key: "total",
          label: "total",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
      ],
      params: { from: new Date(), to: new Date(), menu: "ALL" },
      menus: [],
      menu_id: 0,
    };
  }

  generate = () => {
    this.setState({ data: null });
    this.query({
      [this.object]: {
        stock:
          this.state.tabIdx === 1
            ? this.state.params
            : {
                from: new Date(2000, 1),
                to: new Date(),
                menu_id: this.state.menu_id
              },
      },
    }).then((data) => {
      this.setState({ data: data[this.object].stock });
    });
  };

  titles = () => ["Current Stock", "Range Stock"];

  toolbar = () => (
    <CrudToolbar
      leftForm={
        this.state.tabIdx === 1 ? (
          <FormInline>
            <EditDateTime name="from" data={this.state.params} />
            <EditDateTime name="to" data={this.state.params} />
            <EditSelect
              name="menu_id"
              data={this.state}
              options={this.state.menus}
            />
            <GenerateBtn onClick={this.generate} />
          </FormInline>
        ) : (
          <FormInline>
            <EditSelect
              name="menu_id"
              data={this.state}
              options={this.state.menus}
            />
            <GenerateBtn onClick={this.generate} />
          </FormInline>
        )
      }
      tabs={
        <Tabs
          value={this.state.tabIdx}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, idx) => {
            this.setState({ tabIdx: idx });
          }}
        >
          <Tab
            label={
              <Typography>
                <Lang>CURRENT STOCK</Lang>
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                <Lang>RANGE STOCK</Lang>
              </Typography>
            }
          />
        </Tabs>
      }
      left={<Info title={this.titles()[this.state.tabIdx]} />}
      onSearch={(e) => this.setState({ keyword: e.target.value.toLowerCase() })}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
        </GroupBtn>
      }
    />
  );

  componentDidMount() {
    this.query({
      menu: { read: { fields: ["id", "name"] } },
    }).then((data) => {
      this.setState({
        menus: [[0, "ALL"]].concat(data.menu.read),
      });
    });
    this.setState({ data: [] });
  }
}
