import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import { Grid, TextField, InputAdornment } from "@material-ui/core";

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    minHeight: "30px",
    flexGrow: 1
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  container: {
    justifyContent: "space-between"
  }
}));

export default function CrudToolbar(props) {
  const classes = useToolbarStyles();
  return (
    <div>
      {props.tabs || null}
      <Toolbar className={classes.root} variant="dense">
        <Grid container>
          <Grid container xs={12} className={classes.container}>
            <Grid item className={classes.left}>
              {props.left}
            </Grid>
            <Grid item className={classes.right}>
              {props.right}
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.container}>
            <Grid item className={classes.left}>
              {props.leftForm || <Typography>{props.count} Rows</Typography>}
            </Grid>
            <Grid item className={classes.right}>
              <TextField
                placeholder={"Search..."}
                onChange={props.onSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </div>
  );
}
