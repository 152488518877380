import React, { useState, useEffect } from "react";

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import PropTypes from "prop-types";
import SettingsIcon from "@material-ui/icons/Settings";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { Post } from "../../api";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography, Select, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "0 1px 8px rgba(0,0,0,.3)",
    position: "relative",
    zIndex: theme.zIndex.drawer + 100,
    background: "url(/static/images/bee.svg)",
    // transform: "rotate(5deg)",
    backgroundSize: "cover",
    backgroundPosition: "center bottom",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
  },
  toolBar: {
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
  },
  branding: {
    display: "flex",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    margin: "auto 0",
    lineHeight: "50px",
    padding: `0 64px 0 0`,
  },
  logo: {
    margin: "auto",
    height: "45px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80px",
    },
  },
  branch: {
    color: "white",
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

const Header = (props) => {
  const {
    history,
    logo,
    logoAltText,
    toggleFullscreen,
    toggleDrawer,
    toogleNotifications,
    routes,
    branches,
    state: appState,
    alarm,
    reload,
  } = props;

  const classes = useStyles();
  const [state, setState] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchExpanded, setSearchExpanded] = useState(false);

  const handleSettingdToggle = (event) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const handleSearchExpandToggle = () => setSearchExpanded(!searchExpanded);

  const handleDrawerToggle = () => {
    toggleDrawer();
    if (searchExpanded) handleSearchExpandToggle();
  };

  const handleNotificationToggle = () => {
    toogleNotifications();
    if (searchExpanded) handleSearchExpandToggle();
  };

  useEffect(() => {
    setState(appState);
    console.log("app state:", appState);
  }, [appState]);

  const [branch, setBranch] = React.useState(0);
  useEffect(() => {
    const br = branches.find((b) => b[3] === 1);
    if (br) {
      setBranch(br[0]);
    }
  }, [branches]);
  const [breadcrumbs, setBreadcrums] = React.useState([]);

  history.listen(({ pathname }) => {
    let last = pathname.split("/");
    last = last[last.length - 1];
    let nB = [];
    routes.items.forEach((r) => {
      if (
        r.path &&
        (r.path === pathname || r.path.replace(":id", last) === pathname)
      ) {
        nB.push({ name: r.breadcrumb || r.name, to: pathname });
      } else if (r.children) {
        let pfx = r.type === "submenu" ? r.path : "";
        r.children.forEach((r1) => {
          let pth = pfx + r1.path;
          if (pth === pathname || pth.replace(":id", last) === pathname) {
            nB.push({ name: r.breadcrumb || r.name, to: r.path });
            nB.push({ name: r1.breadcrumb || r1.name, to: pathname });
          }
        });
      }
    });
    setBreadcrums(nB);
  });

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolBar} variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        <div className={classes.branding}>
          <img src={logo} alt={logoAltText} className={classes.logo} />
        </div>

        <div style={{ marginLeft: "30px", whiteSpace: "nowrap" }}>
          <Paper elevation={0} className={classes.paper}>
            <Breadcrumbs color="inherit" aria-label="breadcrumb">
              {breadcrumbs.map((b) => (
                <Link to={b.path} color="inherit">
                  {b.breadcrumb || b.name}
                </Link>
              ))}
            </Breadcrumbs>
          </Paper>
        </div>
        <Typography
          style={{
            flex: "1 1 50%",
            marginRight: "10px",
            textAlign: "right",
            color: "black",
          }}
        >
          {(branches.find((b) => b[0] === branch) || ["", "", ""])[2]}
        </Typography>
        <Select
          value={branch}
          onChange={(e) => {
            setBranch(e.target.value);
            Post("/refresh", {
              branch_id: parseInt(e.target.value),
            }).then((ok) => {
              reload({});
              console.log(ok);
            });
          }}
        >
          {branches.map((b) => (
            <MenuItem key={b[0]} value={b[0]}>
              {b[1]}
            </MenuItem>
          ))}
        </Select>

        <Hidden xsDown>
          <IconButton color="black" onClick={toggleFullscreen}>
            <FullscreenIcon />
          </IconButton>
        </Hidden>

        <IconButton color="black" onClick={handleNotificationToggle}>
          <Badge badgeContent={alarm.length} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>

        <IconButton
          aria-label="User Settings"
          aria-owns={anchorEl ? "user-menu" : null}
          aria-haspopup="true"
          color={state === 1 ? "default" : "secondary"}
          onClick={handleSettingdToggle}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              Post("/refresh", { dark: true }).then((data) => {
                setState(data.app_state);
                // console.log(data);
                handleCloseMenu();
                window.location.reload();
              });
            }}
          >
            <ListItemIcon>
              <SettingsIcon color={"default"} />
            </ListItemIcon>
            <ListItemText primary="Switch Account" />
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <Link to={"/profile"} color="inherit">
              <ListItemText primary="Profile" />
            </Link>
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <ListItemIcon>
              <NotificationsOffIcon />
            </ListItemIcon>
            <ListItemText primary="Disable notifications" />
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              primary="Sign out"
              onClick={() => {
                Post("/exit", {}).then((ok) => {
                  history.push("/auth");
                });
              }}
            />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

Header.prototypes = {
  logo: PropTypes.string,
  logoAltText: PropTypes.string,
};

export default Header;
