import DateFnsUtils from "@date-io/date-fns";
import { Button, ButtonGroup } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import SwapthisIcon from "@material-ui/icons/Games";
import GetAppIcon from "@material-ui/icons/GetApp";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LoopIcon from "@material-ui/icons/Loop";
import PrintIcon from "@material-ui/icons/Print";
import MovingIcon from "@material-ui/icons/UnfoldLess";
import MovableIcon from "@material-ui/icons/UnfoldMore";
import React from "react";
import ReactToPrint from "react-to-print";
import { formatDate, formatDateTime } from "../../helpers";
import Lang, { useLang } from "../Lang/lang";


export function GroupBtn(props) {
  return (
    <ButtonGroup size="small" variant="text" {...props}>
      {props.children}
    </ButtonGroup>
  );
}

export function ExcelBtn(props) {
  const translator = useLang();
  const handleClick = () => {
    let params = [];
    for (let key in props.crud.state.params) {
      let val = props.crud.state.params[key];
      if (val instanceof Date) {
        params.push([key, new DateFnsUtils().format(val, "hh:mm dd.MM.yyyy")]);
      } else {
        params.push([key, val]);
      }
    }

    let filter =  props.filter ||
      (props.crud.state.data.length > 0
        ? props.crud.state.data[0].map((d, idx) => idx)
        : []);

    if (props.removeEmptySum && !props.filter) {
      filter = [];
      props.crud.state.columns.forEach((c, idx) => {
        if (!c.numeric || !c.sum) {
          filter.push(idx);
          return;
        }
        const tot = props.crud.state.data.reduce(function (old, row) {
          return old + parseFloat(row[idx]);
        }, 0);
        if (tot > 0) {
          filter.push(idx);
        }
      });
    }

    window.excelify(
      props.crud.state.title,
      params,
      props.crud.state.columns.map((c) => ({
        label: translator(c.label),
        numeric: c.numeric || false,
        format:
          c.render === formatDate || c.render === formatDateTime
            ? c.render
            : (v) => v,
        sum: c.sum || false,
      })),
      props.crud.state.data,
      filter,
    );
  };

  let nProps = Object.assign({}, props);
  delete nProps["crud"];
  return (
    <Button {...nProps} onClick={handleClick} startIcon={<GetAppIcon />}>
      <Lang>Excel</Lang>
    </Button>
  );
}

export function GenerateBtn(props) {
  return (
    <Button
      {...props}
      variant="outlined"
      color="primary"
      startIcon={<LoopIcon />}
    >
      <Lang>Generate</Lang>
    </Button>
  );
}

export function BookBtn(props) {
  return (
    <Button
      {...props}
      variant="outlined"
      color="primary"
      startIcon={<LibraryBooksIcon />}
    >
      <Lang>Download</Lang>
    </Button>
  );
}

export function PrintBtn(props) {
  return (
    <ReactToPrint
      trigger={() => (
        <Button {...props} startIcon={<PrintIcon />}>
          <Lang>Print</Lang>
        </Button>
      )}
      content={() => props.refPrint}
    />
  );
}

export function PrintBtnRow(props) {
  return (
    <Button {...props} startIcon={<PrintIcon />}>
      {props.name}
    </Button>
  );
}

export function AddBtn(props) {
  return (
    <Button {...props} startIcon={<AddIcon />}>
      <Lang>Add</Lang> <Lang>{props.object ?? ""}</Lang>
    </Button>
  );
}

export function EditBtn(props) {
  return (
    <Button {...props} startIcon={<EditIcon />}>
      <Lang>Edit</Lang>
    </Button>
  );
}

export function MoveBtn(props) {
  let Elem = MovableIcon;
  if (!props.moving) {
  } else if (props.id === props.moving) {
    Elem = MovingIcon;
  } else if (props.moving !== 0) {
    Elem = SwapthisIcon;
  }
  return (
    <Button {...props} size="small">
      <Elem />
    </Button>
  );
}

export function StateBtn(props) {
  const handleClick = () => {
    props.onClick && props.onClick(props.state === 0 ? 1 : 0);
  };
  return props.state !== 0 ? (
    <Button
      {...props}
      color="secondary"
      startIcon={<BlockIcon />}
      onClick={handleClick}
    >
      <Lang>Disable</Lang>
    </Button>
  ) : (
    <Button
      {...props}
      color="primary"
      startIcon={<CheckIcon />}
      onClick={handleClick}
    >
      <Lang>Enable</Lang>
    </Button>
  );
}
