import React, { useEffect } from "react";
import {
  Paper,
  FormControl,
  Input,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import Query from "../api";
import Lang from "../components/Lang/lang";

export default function Profile(props) {
  let [state, setState] = React.useState({});

  let makeInput = (name) => ({
    name: name,
    value: state[name] || "",
    onChange: (e) => {
      const value = e.target.value;
      setState((st) => {
        let nst = {
          ...st,
          [name]: value,
        };
        Query({
          config: { update: nst },
        });
        return nst;
      });
    },
  });

  useEffect(() => {
    Query({
      config: {
        read: state,
      },
    }).then((resp) => {
      if (Object.keys(resp.config.read).length > 0) {
        setState({
          company_name: resp.config.read["company_name"],
          company_email: resp.config.read["company_email"],
          company_nr: resp.config.read["company_nr"],
          company_nrb: resp.config.read["company_nrb"],
          company_nrt: resp.config.read["company_nrt"],
          company_nrf: resp.config.read["company_nrf"],
          company_swift: resp.config.read["company_swift"],
          company_tel: resp.config.read["company_tel"],
          company_address: resp.config.read["company_address"],
        });
      }
    });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Paper style={{ width: "50%", padding: "15px" }}>
        <Typography
          variant="h6"
          color="inherit"
          style={{ marginBottom: "0.5rem" }}
        >
          <Lang>Company info</Lang>
        </Typography>
        <FormControl>
          <TextField
            {...makeInput("company_name")}
            label="Name"
            variant="outlined"
            margin="dense"
          />
        </FormControl>
        <br />
        <FormControl>
          <TextField
            {...makeInput("company_email")}
            label="Email"
            variant="outlined"
            margin="dense"
          />
        </FormControl>
        <br />
        <FormControl>
          <TextField
            {...makeInput("company_nr")}
            label="Nr. Unik"
            variant="outlined"
            margin="dense"
          />
        </FormControl>
        <br />
        <FormControl>
          <TextField
            {...makeInput("company_nrb")}
            label="NRB"
            variant="outlined"
            margin="dense"
          />
        </FormControl>
        <br />
        <FormControl>
          <TextField
            {...makeInput("company_nrt")}
            label="NRT"
            variant="outlined"
            margin="dense"
          />
        </FormControl>
        <br />
        <FormControl>
          <TextField
            {...makeInput("company_nrf")}
            label="NRF"
            variant="outlined"
            margin="dense"
          />
        </FormControl>
        <br />
        <FormControl>
          <TextField
            {...makeInput("company_tel")}
            label="Phone Nr."
            variant="outlined"
            margin="dense"
          />
        </FormControl>
        <br />
        <FormControl>
          <TextField
            {...makeInput("company_address")}
            label="Address"
            variant="outlined"
            margin="dense"
          />
        </FormControl>
        <br />
        <FormControl>
          <TextField
            {...makeInput("company_swift")}
            label="Swift"
            variant="outlined"
            margin="dense"
          />
        </FormControl>
        <br />
      </Paper>
    </div>
  );
}
