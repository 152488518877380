export default function compare(row, idx, order) {
  if (!row || row.length === 0) {
    return undefined;
  }

  order = order ? 1 : -1;
  let process = v => v;
  let val = row[idx];
  if (isNaN(val) && val.split(".").length === 3) {
    if (val.indexOf(" ") > -1) {
      let arr = val.split(" ");
      let arr0 = arr[0].split(":");
      let arr1 = arr[1].split(".");
      if (arr0.length === 2 && arr1.length === 3) {
        process = v => {
          let arr = v.split(" ");
          let arr0 = arr[0].split(":");
          let arr1 = arr[1].split(".");
          return new Date(
            arr1[2],
            arr1[1],
            arr1[0],
            arr0[0],
            arr0[1],
            0
          ).getTime();
        };
      } else if (arr0.length === 3 && arr1.length === 3) {
        process = v => {
          let arr = v.split(" ");
          let arr0 = arr[0].split(":");
          let arr1 = arr[1].split(".");
          return new Date(
            arr1[2],
            arr1[1],
            arr1[0],
            arr0[0],
            arr0[1],
            arr0[2]
          ).getTime();
        };
      }
    } else {
      process = v => {
        let arr = v.split(".");
        return new Date(arr[2], arr[1], arr[0]).getTime();
      };
    }
  }
  return function(a, b) {
    a = process(a[idx]);
    b = process(b[idx]);
    if (a < b) return order;
    if (a > b) return -order;
    return 0;
  };
}
