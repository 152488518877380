import React from 'react';
import { EditBtn, GroupBtn, StateBtn } from '../components/CRUD/btn';
import CRUD from '../components/CRUD/crud';
import { EditCheckbox, EditInput } from '../components/CRUD/fields';
import FormDialog from '../components/CRUD/form';
import { formatPrice } from '../helpers';

export default class Client extends CRUD {
  constructor(props) {
    super(props);
    this.object = 'client';
    this.state = {
      title: "Clients",
      columns: [
        {key: "id", label: "ID", numeric: true},
        {key: "name", label: "Name"},
        {key: "NRF", label: "NRF"},
        {key: "NRB", label: "NRB"},
        {key: "NRT", label: "NRT"},
        {key: "address", label: "Address"},
        {key: "contact", label: "Contact"},
        {key: "debt", label: "Debt", sum: true, render: formatPrice, numeric: true},
        {key: "rabat", label: "Rabat", render: (v) => v.toFixed(2) + "%", numeric: true},
        {key: "state", label: "Action", render: (v, row) => (
          <GroupBtn>
            <EditBtn onClick={() => this.onEdit(row)}/>
            <StateBtn state={v} onClick={(v) => this.onSaveForm(row, {state: v})}/>
          </GroupBtn>
        )},
      ]
    }
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name='name'/>
        <EditInput name='NRF'/>
        <EditInput name='NRB'/>
        <EditInput name='NRT'/>
        <EditInput name='address'/>
        <EditInput name='contact'/>
        <EditInput name='email'/>
        <EditInput name='rfid' type='password'/>
        <EditInput name='debt' type='number'/>
        <EditInput name='rabat' type='number'/>
        <EditCheckbox name='no_vat' />
      </FormDialog>
    );
  }
}