import React from "react";
import {
  ExcelBtn,
  GenerateBtn, GroupBtn,
  PrintBtn
} from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import { EditInput } from "../components/CRUD/fields";
import { FormInline } from "../components/CRUD/form";
import Info from "../components/CRUD/info";
import CrudToolbar from "../components/CRUD/toolbar";
import { formatPrice } from '../helpers';

export default class PurchaseOver500 extends CRUD {
  constructor(props) {
    super(props);
    this.object = "report";
    this.state = {
      title: "Purchase Over 500",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "invoice_nr", label: "Invoice Nr" },
        { key: "supplier", label: "Supplier" },
        {
          key: "total",
          label: "Total",
          sum: true,
          numeric: true,
          render: formatPrice,
        },
      ],
      params: { year: new Date().getFullYear() },
    };
  }

  generate = () => {
    this.setState({ data: null });

    let where = [
      "issued_on >= " + new Date(this.state.params.year, 0).toISOString(),
      "issued_on <= " + new Date(this.state.params.year + 1, 0).toISOString(),
    ];

    this.query({
      [this.object]: {
        purchaseOver: {
          fields: this.state.columns.map((c) => c.key),
          where: where,
        },
      },
    }).then((data) => {
      this.setState({ data: data[this.object].purchaseOver });
    });
  };

  toolbar = () => (
    <CrudToolbar
      leftForm={
        <FormInline>
          <EditInput name="year" data={this.state.params} />
          <GenerateBtn onClick={this.generate} />
        </FormInline>
      }
      left={<Info title={this.state.title} />}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
        </GroupBtn>
      }
    />
  );

  componentDidMount() {
    this.setState({ data: [] });
  }
}
