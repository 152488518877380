import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Lang from "../Lang/lang";
import { Grid } from "@material-ui/core";
import Query from "../../api";

export default function FormDialog(props) {
  const [errors, setErrors] = React.useState({});
  const id = props.data.id;
  const onSave = (next) => {
    props.crud.onSaveForm(id, props.data, setErrors, next, props.onSave);
  };

  const onClose = () => props.crud.closeForm();
  return (
    <Dialog
      maxWidth="xs"
      open={props.crud.state.showForm}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Lang>{props.crud.state.title}</Lang>
      </DialogTitle>
      <DialogContent>
        {React.Children.map(props.children, (c) =>
          c === null
            ? null
            : React.cloneElement(c, {
                key: c.props.name,
                data: c.props.data || props.data,
                error: errors[c.props.name] && errors[c.props.name].length > 0,
                helperText: (errors[c.props.name] || []).join(", "),
              })
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          <Lang>Cancel</Lang>
        </Button>
        <Button onClick={() => onSave(false)} color="primary">
          <Lang>OK</Lang>
        </Button>
        {props.data.id ? null : (
          <Button onClick={() => onSave(true)} color="primary">
            <Lang>OK & Continue</Lang>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export function FormInline(props) {
  return (
    <Grid container style={{ alignItems: "center" }} spacing={2}>
      {React.Children.map(props.children, (c) => (
        <Grid item>{c}</Grid>
      ))}
    </Grid>
  );
}

export function FormDialogBody(props) {
  const onSave = props.onSave;
  const onClose = () => props.crud.closeForm();
  return (
    <Dialog
      maxWidth="xs"
      open={props.crud.state.showForm}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Lang>{props.title}</Lang>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          <Lang>Cancel</Lang>
        </Button>
        <Button onClick={() => onSave()} color="primary">
          <Lang>OK</Lang>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function FormDialog2(props) {
  const [errors, setErrors] = React.useState({});
  const id = props.data.id;
  const onSave = (next) => {
    Query({
      [props.object]: {
        create: props.data,
      },
    }).then((data) => {
      if (data[props.object].type === "field") {
        let err = data[props.object];
        setErrors({ [err.field]: [err.msg] });
      } else {
        props.onSave(data[props.object].create);
      }
    });
  };
  return (
    <Dialog
      maxWidth="xs"
      open={true}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Lang>{props.title}</Lang>
      </DialogTitle>
      <DialogContent>
        {React.Children.map(props.children, (c) =>
          c === null
            ? null
            : React.cloneElement(c, {
                key: c.props.name,
                data: c.props.data || props.data,
                error: errors[c.props.name] && errors[c.props.name].length > 0,
                helperText: (errors[c.props.name] || []).join(", "),
              })
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          <Lang>Cancel</Lang>
        </Button>
        <Button onClick={() => onSave(false)} color="primary">
          <Lang>OK</Lang>
        </Button>
        {props.data.id ? null : (
          <Button onClick={() => onSave(true)} color="primary">
            <Lang>OK & Continue</Lang>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
