import React from "react";
import CRUD from "../components/CRUD/crud";
import CrudToolbar from "../components/CRUD/toolbar";
import Info from "../components/CRUD/info";
import {
  GroupBtn,
  PrintBtn,
  ExcelBtn,
  GenerateBtn
} from "../components/CRUD/btn";
import { EditDateTime } from "../components/CRUD/fields";
import { FormInline } from "../components/CRUD/form";
import { Tabs, Tab, Typography } from "@material-ui/core";
import { formatPrice } from "../helpers";
import Lang from "../components/Lang/lang";

export default class Top extends CRUD {
  constructor(props) {
    super(props);
    this.object = "report";
    this.state = {
      title: "Top",
      tabIdx: 0,
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Name" },
        { key: "qty", label: "Quantity", numeric: true },
        { key: "total", label: "Total", numeric: true, render: formatPrice }
      ],
      params: { from: new Date(), to: new Date() }
    };
  }

  generate = () => {
    this.setState({ data: null });

    let req = "TopWaiter";
    if (this.state.tabIdx === 1) {
      req = "TopArticle";
    } else if (this.state.tabIdx === 2) {
      req = "TopTable";
    }

    let where = [
      "issued_on > " + this.state.params.from.toISOString(),
      "issued_on < " + this.state.params.to.toISOString()
    ];

    this.query({
      [this.object]: {
        [req]: {
          fields: this.state.columns.map(c => c.key),
          where: where
        }
      }
    }).then(data => {
      this.setState({ data: data[this.object][req] });
    });
  };

  titles = () => ["Waiter", "Article", "Table"];

  toolbar = () => (
    <CrudToolbar
      leftForm={
        <FormInline>
          <EditDateTime name="from" data={this.state.params} />
          <EditDateTime name="to" data={this.state.params} />
          <GenerateBtn onClick={this.generate} />
        </FormInline>
      }
      tabs={
        <Tabs
          value={this.state.tabIdx}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, idx) => {
            this.setState({ tabIdx: idx, data: [] });
          }}
        >
          <Tab
            label={
              <Typography>
                <Lang>WAITER</Lang>
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                <Lang>ARTICLE</Lang>
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                <Lang>TABLE</Lang>
              </Typography>
            }
          />
        </Tabs>
      }
      left={<Info title={this.titles()[this.state.tabIdx]} />}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
        </GroupBtn>
      }
    />
  );

  componentDidMount() {
    this.setState({ data: [] });
  }
}
