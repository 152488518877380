import React from "react";
import CRUD from "../components/CRUD/crud";
import { GroupBtn, EditBtn, StateBtn } from "../components/CRUD/btn";
import {
  EditInput,
  EditSelect,
  EditBranch,
  EditCheckbox,
} from "../components/CRUD/fields";
import Routes from "../routes";
import FormDialog from "../components/CRUD/form";
import Lang from "../components/Lang/lang";

export default class User extends CRUD {
  constructor(props) {
    super(props);
    this.object = "user";
    this.state = {
      title: "users",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Name" },
        { key: "contact", label: "Contact" },
        {
          key: "color",
          label: "Color",
          render: (v, row) => (
            <input
              type="color"
              defaultValue={v}
              onBlur={(c) => this.onSaveForm(row, { color: c.target.value })}
            ></input>
          ),
        },
        {
          key: "perc",
          label: "Perc",
          render: (v) => v.toFixed(2) + "%",
          numeric: true,
        },
        { key: "type", label: "Type" },
        {
          key: "type_id",
          label: "Access",
          render: (v, row) =>
            v === 1 ? null : (
              <button onClick={() => this.onEdit(row, true)}>
                <Lang>Access</Lang>
              </button>
            ),
        },
        {
          key: "routes",
          label: "Routes",
          render: (v, row) => (
            <button onClick={() => this.onEdit(row, false, true)}>
              <Lang>Routes</Lang>
            </button>
          ),
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
      type_id: 1,
    };
  }

  createForm = (data) => {
    // 1 admin, 2 manager, 3 kamarier
    if (this.state.access) {
      data[this.object].one = {
        id: data[this.object].one.id,
        access: data[this.object].one.access,
      };
      return (
        <FormDialog crud={this} data={data[this.object].one}>
          {Object.keys(data[this.object].one.access).map((k) =>
            k === "routes" ? null : (
              <EditCheckbox
                key={k}
                name={k}
                data={data[this.object].one.access}
                useBool={true}
              />
            )
          )}
        </FormDialog>
      );
    } else if (this.state.routes) {
      let routes = data[this.object].one.access.routes || [];
      let list = {};
      Routes.items.forEach((route) => {
        if (typeof route.path === "string") {
          list[route.path] = routes.includes(route.path);
        }
      });
      data[this.object].one = {
        id: data[this.object].one.id,
        routes: list,
      };
      return (
        <FormDialog crud={this} data={data[this.object].one}>
          {Routes.items.map((route) =>
            typeof route.path === "string" ? (
              <EditCheckbox
                key={route.path}
                name={route.path}
                data={list}
                useBool={true}
              />
            ) : null
          )}
        </FormDialog>
      );
    } else if (data[this.object].one) {
      delete data[this.object].one.access;
    }

    if (!data[this.object].one) {
      data[this.object]["one"] = {};
    }

    return (
      <FormDialog crud={this} data={data[this.object].one}>
        <EditInput name="user" />
        <EditInput name="name" />
        <EditInput name="contact" />
        <EditInput name="color" type="color" />
        <EditSelect
          name="type_id"
          options={data.user.readType}
          onChange={(val) => {
            this.setState({ type_id: val });
          }}
        />
        <EditInput name="pass" type="password" />
        {this.state.type_id !== 1 ? (
          <EditInput name="rfid" type="password" />
        ) : null}
        {this.state.type_id === 3 ? (
          <EditInput name="perc" type="number" />
        ) : null}
        <EditBranch name="branch" branches={data.branch.read} />
      </FormDialog>
    );
  };

  onEdit = (row, access, routes) => {
    if (access) {
      let req = {};
      if (row[this.key]) {
        req[this.object] = { one: row[this.key] };
      }
      this.query(req).then((data) => {
        this.setState({ showForm: true, formData: data, access: true });
      });
    } else if (routes) {
      let req = {};
      if (row[this.key]) {
        req[this.object] = { one: row[this.key] };
      }
      this.query(req).then((data) => {
        this.setState({
          showForm: true,
          formData: data,
          access: false,
          routes: true,
        });
      });
    } else {
      let req = {
        user: { readType: { fields: ["id", "name"] } },
        branch: { read: { fields: ["id", "name"] } },
      };
      if (row[this.key]) {
        Object.assign(req[this.object], { one: row[this.key] });
      }
      this.query(req).then((data) => {
        let type_id = 1;
        if (data[this.object] && data[this.object].one) {
          type_id = data[this.object].one.type_id;
        }
        this.setState({
          showForm: true,
          formData: data,
          type_id: type_id,
          access: false,
        });
      });
    }
  };
}
