import { Line } from "react-chartjs-2";
import { StatCard, Wrapper } from "../components";
import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import EmailIcon from "@material-ui/icons/Email";
import Grid from "@material-ui/core/Grid";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Paper from "@material-ui/core/Paper";
import PhoneIcon from "@material-ui/icons/Phone";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Query from "../api";
import { Toolbar, Typography, CircularProgress } from "@material-ui/core";
import { formatPrice } from "../helpers";
import Lang from "../components/Lang/lang";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graph: this.saleData({}),
      cdebt: 0,
      sdebt: 0,
      profit: 0,
      invoices: 0,
      articles: null,
      tables: null,
      waiters: null
    };
  }

  saleData = data => ({
    labels: data.labels || ["", "", "", "", "", "", "", "", "", ""],
    datasets: [
      {
        label: "10 ditet e fundit",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data.data || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    ]
  });

  componentDidMount() {
    Query({ dashboard: { graph: 1 } }).then(data => {
      this.setState({ graph: this.saleData(data.dashboard.graph) });
    });
    Query({ dashboard: { credit: 1 } }).then(data => {
      this.setState({ cdebt: data.dashboard.credit });
    });
    Query({ dashboard: { debit: 1 } }).then(data => {
      this.setState({ sdebt: data.dashboard.debit });
    });
    Query({ dashboard: { profit: 1 } }).then(data => {
      this.setState({ profit: data.dashboard.profit });
    });
    Query({ dashboard: { count: 1 } }).then(data => {
      this.setState({ invoices: data.dashboard.count });
    });
    Query({ dashboard: { articles: 1 } }).then(data => {
      this.setState({ articles: data.dashboard.articles });
    });
    Query({ dashboard: { waiters: 1 } }).then(data => {
      this.setState({ waiters: data.dashboard.waiters });
    });
    Query({ dashboard: { tables: 1 } }).then(data => {
      this.setState({ tables: data.dashboard.tables });
    });
  }

  render() {
    return (
      <>
        <Wrapper>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title={<Lang>Client debt</Lang>}
                value={this.state.cdebt.toFixed(2)}
                icon={<LocalOfferIcon />}
                color="#3f51b5"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title={<Lang>Supplier debt</Lang>}
                value={this.state.sdebt.toFixed(2)}
                icon={<PhoneIcon />}
                color="#9c27b0"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title={<Lang>Issued invoices</Lang>}
                value={this.state.invoices}
                icon={<EmailIcon />}
                color="#ffd740"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                type="fill"
                title={<Lang>Profit</Lang>}
                value={this.state.profit.toFixed(2)}
                icon={<NotificationsIcon />}
                color="#f44336"
              />
            </Grid>
          </Grid>
        </Wrapper>
        <Wrapper>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardContent>
                <Line data={this.state.graph} height={50} />
              </CardContent>
            </Card>
          </Grid>
        </Wrapper>
        <Wrapper>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={4}>
              <TopTable
                title={"tables"}
                header={["Name", "NrInvoices", "Total"]}
                data={this.state.tables}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TopTable
                title={"articles"}
                header={["Name", "Qty", "Total"]}
                data={this.state.articles}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TopTable
                title={"waiters"}
                header={["Name", "NrInvoices", "Total"]}
                data={this.state.waiters}
              />
            </Grid>
          </Grid>
        </Wrapper>
      </>
    );
  }
}

function TopTable(data) {
  let rows = data.data;
  let header = data.header;
  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" id="tableTitle">
          Top <Lang>{data.title}</Lang>
        </Typography>
      </Toolbar>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Lang>{header[0]}</Lang>
            </TableCell>
            <TableCell align="right">
              <Lang>{header[1]}</Lang>
            </TableCell>
            <TableCell align="right">
              <Lang>{header[2]}</Lang>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rows || []).map(row => (
            <TableRow key={row.name}>
              <TableCell>
                <Lang>{row.name}</Lang>
              </TableCell>
              <TableCell align="right">{row.cnt}</TableCell>
              <TableCell align="right">{formatPrice(row.total)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {rows == null ? (
        <div style={{ padding: 10, textAlign: "center" }}>
          <CircularProgress />
          <Typography>Loading...</Typography>
        </div>
      ) : null}
    </Paper>
  );
}
