import { Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { createContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Query, { setHistory } from "./api";
import { Header, NotificationCenter, Sidebar, Workspace } from "./components";
import useMountEffect from "./mountEffect";
import { MobileBreakpoint } from "./styleVariables";


const useStyles = makeStyles((theme) => ({
  panel: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      minHeight: "calc(100vh - 64px)",
      paddingTop: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      minHeight: "calc(100vh - 56px)",
      paddingTop: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 64px)",
    },
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 3,
  },
}));

export const BranchCtx = createContext([]);

const Main = ({ state, routes }) => {
  const history = useHistory();
  setHistory(history);

  const classes = useStyles();
  const [opened, setOpened] = useState(true);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [, setOpenSpeedDial] = useState(false);
  // const [routes, setRoutes] = useState({items: []});

  const mediaMatcher = matchMedia(`(max-width: ${MobileBreakpoint}px)`);

  const resizeDispatch = () => {
    if (typeof Event === "function") {
      window.dispatchEvent(new Event("resize"));
    } else {
      const evt = window.document.createEvent("UIEvents");
      evt.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(evt);
    }
  };

  const handleDrawerToggle = () => {
    setOpened(!opened);
    resizeDispatch();
  };

  const handleNotificationToggle = () =>
    setNotificationsOpen(!notificationsOpen);

  const handleFullscreenToggle = () => {
    const element = document.querySelector("#root");
    const isFullscreen =
      document.webkitIsFullScreen || document.mozFullScreen || false;

    element.requestFullScreen =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      function () {
        return false;
      };
    document.cancelFullScreen =
      document.cancelFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      function () {
        return false;
      };
    isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };

  const getRoutes = (
    <Switch>
      {routes.items
        .filter((r) => r.type !== "category")
        .map((item, index) => {
          switch (item.type) {
            case "external":
              return (
                <Route
                  exact
                  path={item.path}
                  component={item.component}
                  name={item.name}
                  key={index}
                />
              );
            case "submenu":
              return item.children.map((subItem) => (
                <Route
                  exact
                  path={`${item.path}${subItem.path}`}
                  component={subItem.component}
                  name={subItem.name}
                />
              ));
            default:
              let nRoutes = [
                <Route
                  exact
                  path={item.path}
                  component={item.component}
                  name={item.name}
                  key={index}
                />,
              ];
              nRoutes = nRoutes.concat(
                (item.children || []).map((hr, idx) => (
                  <Route
                    exact
                    path={hr.path}
                    component={hr.component}
                    key={index + "_" + idx}
                  />
                ))
              );
              return nRoutes;
          }
        })}
      <Redirect to="/" />
    </Switch>
  );

  useMountEffect(() => {
    if (mediaMatcher.matches) setOpened(false);
    mediaMatcher.addListener((match) => {
      setTimeout(() => {
        if (match.matches) setOpened(false);
        else setOpened(true);
      }, 300);
    });

    const unlisten = history.listen(() => {
      if (mediaMatcher.matches) setOpened(false);
      document.querySelector("#root > div > main").scrollTop = 0;
    });

    return () => {
      unlisten();
      mediaMatcher.removeListener((match) => {
        setTimeout(() => {
          if (match.matches) setOpened(false);
          else setOpened(true);
        }, 300);
      });
    };
  });

  const [branches, setBranches] = React.useState([]);
  const [alarm, setAlarm] = useState([]);
  const [reload, setReload] = useState({});

  useEffect(() => {
    Query({
      branch: {
        read: { fields: ["id", "name", "last_sync", "active"] },
      },
      item: {
        read: {
          fields: ["name", "stock", "unit"],
          where: ["stock < alarm", "type = 0"],
        },
      },
      user: {
        getUser: {},
      },
    }).then((data) => {
      setBranches(data.branch.read);
      setAlarm(data.item.read);
    });
  }, [reload, routes]);

  return (
    <Fade in={true}>
      <>
        <Header
          reload={setReload}
          routes={routes}
          alarm={alarm}
          branches={branches}
          state={state}
          history={history}
          logoAltText="BEE IT Admin"
          logo={`/static/images/logo.svg`}
          toggleDrawer={handleDrawerToggle}
          toogleNotifications={handleNotificationToggle}
          toggleFullscreen={handleFullscreenToggle}
        />
        <div className={classNames(classes.panel, "theme-dark")}>
          <Sidebar
            routes={routes.items}
            opened={opened}
            toggleDrawer={handleDrawerToggle}
          />
          <Workspace opened={opened}>{getRoutes}</Workspace>
          <NotificationCenter
            alarm={alarm}
            notificationsOpen={notificationsOpen}
            toogleNotifications={handleNotificationToggle}
          />
        </div>
      </>
    </Fade>
  );
};

export default Main;
