import React from "react";
import { formatPrice } from "../helpers";
import Crud from "../components/CRUD/crud";

export default class CashRegisterItems extends Crud {
  constructor(props) {
    super(props);
    this.object = "cashregister_item"; // server side object name
    this.state = {
      title: "Cash register items",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Name" },
        {
          key: "amount",
          label: "Qty",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
        { key: "price", label: "Price", render: formatPrice, numeric: true },
        {
          key: "total",
          label: "Total",
          sum: true,
          render: formatPrice,
          numeric: true,
        },
      ],
    };
  }

  componentDidMount() {
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["cash_register_id = " + this.props.match.params.id],
        },
      },
    }).then((data) => {
      this.setState({ data: data[this.object].read });
    });
  }
}
