import React from "react";
import Crud from "../components/CRUD/crud";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Wrapper } from "../components";
import { formatPrice } from "../helpers";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import Query from "../api";

export default class IncomeStatement extends Crud {
  constructor(props) {
    super(props);
    //this.printRef = this;
    this.state = {
      name: "",
      nrf: "",
      nrt: "",
      contact: "",
      title: "Income Statement",
      data: [
        {
          title: "Te hyrat",
          data: [
            {
              title: "Te hyrat operuese",
              data: [
                {
                  name: "Te hyrat nga shitja e mallrave",
                  amount: 0.0,
                },
                {
                  name: "Zbritjet e lejuara - rabatet",
                  amount: 0.0,
                },
              ],
              totals: [
                {
                  name: "Te hyrat",
                  amount: 0.0,
                },
              ],
            },
          ],
        },
        {
          title: "Kostoja",
          data: [
            {
              title: "Kostoja e shitjes",
              data: [
                {
                  name: "Kostoja e shitjes se mallrave",
                  amount: 0.0,
                },
              ],
              totals: [
                {
                  name: "Kostoja e shitjes",
                  amount: 0.0,
                },
                {
                  name: "Fitimi bruto",
                  amount: 0.0,
                },
              ],
            },
          ],
        },
        {
          title: "Shenzimet",
          data: [
            {
              title: "Shenzimet operuese",
              data: [
                {
                  name: "Shenzimet operuese",
                  amount: 0.0,
                },
              ],
              totals: [],
            },
          ],
        },
        {
          title: "Jashte bilancore",
          data: [
            {
              title: "Zerat jashte bilancore",
              data: [
                {
                  name: "Shpenzimet e papranuara",
                  amount: 0.0,
                },
              ],
              totals: [
                {
                  name: "Zerat jashte bilancore",
                  amount: 0.0,
                },
                {
                  name: "Jashte bilancore",
                  amount: 0.0,
                },
              ],
            },
          ],
        },
        {
          title: "Fitimi",
          data: [
            {
              title: "",
              data: [
                {
                  name: "Fitimi para tatimit",
                  amount: 0.0,
                },
                {
                  name: "Tatimi",
                  amount: 0.0,
                },
                {
                  name: "Fitimi pas tatimit",
                  amount: 0.0,
                },
                {
                  name: "Pozicionet jashte-bilancore",
                  amount: 0.0,
                },
              ],
              totals: [
                {
                  name: "Fitimi i periudhes",
                  amount: 0.0,
                },
              ],
            },
          ],
        },
      ],
    };
  }

  body = () => {
    return (
      <Wrapper>
        <table>
          <thead>
            <tr>
              <th>
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="font-weight-bold"
                    style={{ textAlign: "right" }}
                  >
                    PASQYRA E TE ARDHURAVE
                  </Typography>
                  <Typography
                    variant="body1"
                    className="mb-1"
                    style={{ textAlign: "right" }}
                  >
                    <Link to="/">Intervali 2020</Link>
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    alignItems="flex-start"
                    direction="row"
                    justify="space-between"
                  >
                    <Grid item xs={12} style={{ textAlign: "left" }}>
                      <Typography variant="body2" gutterBottom>
                        {this.state.name}
                      </Typography>
                      <Typography variant="body1">
                        NRF: {this.state.nrf}
                      </Typography>
                      <Typography variant="body1">
                        NRT: {this.state.nrt}
                      </Typography>
                      <Typography variant="body1">
                        TEL: {this.state.contact}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <CardContent>
                  <Grid
                    container
                    alignItems="flex-start"
                    direction="row"
                    justify="space-between"
                  >
                    <ReportLine data={this.state.data} />
                  </Grid>
                </CardContent>
              </td>
            </tr>
          </tbody>
        </table>
      </Wrapper>
    );
  };

  componentDidMount() {
    let from = new Date();
    let to = new Date();
    from.setFullYear(from.getFullYear() - 1);
    Query({
      finance: {
        statement: { year: new Date().getFullYear() },
      },
    }).then((data) => {
      let d = this.state.data;
      d[0].data[0].data[0].amount = data.finance.statement.sale_income;
      d[0].data[0].data[1].amount = -data.finance.statement.sale_rabat;
      d[0].data[0].totals[0].amount = data.finance.statement.sale;

      d[1].data[0].data[0].amount = data.finance.statement.sale_cost;
      d[1].data[0].totals[0].amount = data.finance.statement.sale_cost;
      d[1].data[0].totals[1].amount = data.finance.statement.profit_bruto;

      d[2].data[0].data[0].amount = (
        data.finance.statement.expenses || []
      ).reduce((tot, e) => tot + e.amount, 0);
      d[2].data[0].totals = data.finance.statement.expenses || [];

      d[3].data[0].data[0].amount = (
        data.finance.statement.excluded_expenses || []
      ).reduce((tot, e) => tot + e.amount, 0);
      d[3].data[0].totals = data.finance.statement.excluded_expenses || [];

      d[4].data[0].data[0].amount = data.finance.statement.profit_before_tax;
      d[4].data[0].data[1].amount = data.finance.statement.tax;
      d[4].data[0].data[2].amount = data.finance.statement.profit_after_tax;
      d[4].data[0].data[3].amount = d[3].data[0].data[0].amount;
      d[4].data[0].totals[0].amount = data.finance.statement.profit;

      this.setState({ data: d });
      console.log(data);
    });
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: theme.spacing(10),
  },
  leftPadd: {
    paddingLeft: theme.spacing(10),
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    paddingRight: 0,
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  lineTotal: {
    textAlign: "right",
  },
}));

const ReportLine = (props) => {
  const classes = useStyles();
  return props.data.map((t) => (
    <Grid item xs={12} sm={12} className={classes.container}>
      <CardContent className={classes.leftPadd}>
        <Typography variant="h6" gutterBottom>
          {t.title}
        </Typography>
        {t.data.map((t1) => (
          <CardContent className={classes.leftPadd}>
            <Typography variant="h6" gutterBottom>
              {t1.title}
            </Typography>
            <CardContent className={classes.leftPadd}>
              {t1.data.map((t2) => (
                <Grid container xs={12} sm={12}>
                  <Grid item xs={6}>
                    <Typography variant="body1">{t2.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" className={classes.lineTotal}>
                      {formatPrice(t2.amount)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ))}
              <CardContent className={classes.leftPadd}>
                {t1.totals.map((t2) => (
                  <Grid container xs={12} sm={12}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{t2.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.lineTotal}
                      >
                        {formatPrice(t2.amount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))}
              </CardContent>
            </CardContent>
          </CardContent>
        ))}
      </CardContent>
    </Grid>
  ));
};
