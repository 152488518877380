import React from "react";
import CRUD from "../components/CRUD/crud";
import { GroupBtn, EditBtn, StateBtn } from "../components/CRUD/btn";
import { EditInput, EditBranch } from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";
import { NavLink } from "react-router-dom";
import Lang from "../components/Lang/lang";

export default class TableCategory extends CRUD {
  constructor(props) {
    super(props);
    this.object = "table_category";
    this.state = {
      title: "Table Categories",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Name" },
        {
          key: "id",
          label: "List",
          render: (v) => (
            <NavLink to={"/table/" + v}>
              <Lang>List Tables</Lang>
            </NavLink>
          ),
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="name" />
        <EditBranch name="branch" branches={data.branch.read} />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      branch: {
        read: { fields: ["id", "name"] },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      this.setState({ showForm: true, formData: data });
    });
  };
}
