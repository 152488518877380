import { Paper } from "@material-ui/core";
import React from "react";
import Query from "../../api";
import Lang from "../Lang/lang";
import { AddBtn, ExcelBtn, GroupBtn, PrintBtn } from "./btn";
import Info from "./info";
import CRUDTable from "./table";
import "./table.css";
import CrudToolbar from "./toolbar";


export default class Crud extends React.Component {
  constructor(props) {
    super(props);
    this.printRef = this;
    this.key = 0;
    this.state = {
      data: null,
      showForm: false,
      formData: {},
      params: {},
      keyword: "",
      title: "",
      columns: [],
    };
    this.aborters = [];
  }

  query = (q) => {
    const aborter = new AbortController();
    this.aborters.push(aborter);
    return Query(q, aborter);
  };

  data = () => this.state.data;

  onEdit = (row) => {
    if (row[this.key]) {
      this.query({ [this.object]: { one: row[this.key] } }).then((data) => {
        this.setState({ showForm: true, formData: data });
      });
    } else {
      this.setState({ showForm: true, formData: {} });
    }
  };

  onRowAdded = (row) => {};

  onMapFields = (fields) => {
    return fields;
  };

  onSaveForm = (rowOrId, fields, errors, next, onDone) => {
    fields = this.onMapFields(fields);
    let updateId = Array.isArray(rowOrId) ? rowOrId[this.key] : rowOrId;
    let actionName = updateId ? "update" : "create";
    let action = updateId
      ? { update: Object.assign(fields, { id: updateId }) }
      : { create: fields };
    let where = updateId ? ["id = " + updateId] : [];
    if (updateId) {
      action["read"] = {
        fields: this.state.columns.map((c) => c.key),
        where: where,
      };
    } else {
      //action['read'] = { fields: this.state.columns.map(c => c.key), where: ["id = {" + this.object+"_id}"] }
    }
    Query({ [this.object]: action }).then((data) => {
      if (data[this.object].type === "field") {
        let err = data[this.object];
        errors && errors({ [err.field]: [err.msg] });
      } else if (Object.keys(data[this.object][actionName]).length > 0) {
        errors && errors(data[this.object][actionName]);
      } else {
        if (updateId) {
          //data[this.object].read.length > 0
          this.setState({ showForm: false, formData: {} });
          this.swapRows(data[this.object].read);
          onDone && onDone(data[this.object].read);
        } else {
          Query({
            [this.object]: {
              read: {
                fields: this.state.columns.map((c) => c.key),
                where: ["id = " + data[this.object].create],
              },
            },
          }).then((data) => {
            if (next) {
              this.onEdit({});
            } else {
              this.setState({ showForm: false, formData: {} });
            }
            this.swapRows(data[this.object].read);
            onDone && onDone(data[this.object].read);
          });
        }
      }
    });
  };

  // createForm = () => undefined;
  closeForm = () => this.setState({ showForm: false, formData: {} });

  componentDidMount() {
    this.query({
      [this.object]: { read: { fields: this.state.columns.map((c) => c.key) } },
    }).then((data) => {
      this.setState({ data: data[this.object].read });
    });
  }

  componentWillUnmount() {
    this.aborters.forEach((ab) => ab.abort());
  }

  swapRows = (newRows) => {
    let isNewRow = true;
    let nData = this.state.data.map((r) => {
      for (let i = 0; i < newRows.length; i++) {
        if (r[this.key] === newRows[i][this.key]) {
          isNewRow = false;
          return newRows[i];
        }
      }
      return r;
    });

    if (isNewRow && newRows.length > 0) {
      nData.push(newRows[0]);
      this.onRowAdded(newRows[0]);
    }
    this.setState({ data: nData }); //, showForm: false, formData: {}
  };

  toolbar = () => (
    <CrudToolbar
      count={(this.state.data || []).length}
      left={
        <Info
          info={this.state.params}
          title={<Lang>{this.state.title}</Lang>}
        />
      }
      onSearch={(e) => this.setState({ keyword: e.target.value.toLowerCase() })}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
          {this.createForm ? <AddBtn object={this.object} onClick={() => this.onEdit({})} /> : null}
        </GroupBtn>
      }
    />
  );

  body = () => null;

  render() {
    if (this.state.showForm && !this.state.formData[this.object]) {
      this.state.formData[this.object] = { one: {} };
    }
    return (
      <>
        <div style={{ width: "100%" }}>
          <Paper style={{ width: "100%" }}>
            {this.toolbar()}
            {this.body() || (
              <CRUDTable
                toolbar={this.toolbar}
                data={this.state.data}
                keyword={this.state.keyword}
                keyIdx={this.key}
                columns={this.state.columns}
              />
            )}
          </Paper>
        </div>
        {this.state.showForm ? this.createForm(this.state.formData) : null}
      </>
    );
  }
}
