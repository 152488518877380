import React, { useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  ListItemText,
  Button,
  Grid,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import BigNumber from "bignumber.js";
import { Autocomplete } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import Lang from "../Lang/lang";

export default function Field(props) {
  return null;
}

export function EditInput(props) {
  let defaultVal = props.data[props.name] || (props.type === "number" ? 0 : "");
  props.data[props.name] = defaultVal;
  return (
    <TextField
      key={defaultVal}
      fullWidth
      {...props}
      style={{ marginBottom: "5px" }}
      type={props.type || "text"}
      defaultValue={defaultVal}
      label={<Lang>{props.name}</Lang>}
      onChange={(e) => {
        if (props.type === "number") {
          let num = parseFloat(e.target.value);
          props.data[props.name] = num;
        } else {
          props.data[props.name] = e.target.value;
        }
      }}
      margin="dense"
      variant="outlined"
      placeholder={props.label}
    />
  );
}

export function EditDate(props) {
  let defaultVal = props.data[props.name] || new Date();
  const [selectedDate, setSelectedDate] = React.useState(defaultVal);
  props.data[props.name] = defaultVal;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        disableToolbar
        inputVariant="outlined"
        format="dd.MM.yyyy"
        margin="normal"
        margin="dense"
        label={<Lang>{props.name}</Lang>}
        value={selectedDate}
        onChange={(date) => {
          props.data[props.name] = date;
          setSelectedDate(date);
        }}
        fullWidth
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export function EditTime(props) {
  const formatTime = "HH:mm:ss";
  let defaultVal = props.data[props.name] || "00:00:00";
  const [selectedDate, setSelectedDate] = React.useState(
    new DateFnsUtils().parse(defaultVal, formatTime)
  );
  props.data[props.name] = defaultVal;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        {...props}
        disableToolbar
        variant="outlined"
        // margin="normal"
        format={formatTime}
        margin="dense"
        ampm={false}
        label={<Lang>{props.name}</Lang>}
        value={selectedDate}
        onChange={(date) => {
          props.data[props.name] = new DateFnsUtils().format(date, formatTime);
          setSelectedDate(date);
        }}
        fullWidth
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export function EditDateTime(props) {
  let defaultVal = props.data[props.name] || new Date();
  const [selectedDate, setSelectedDate] = React.useState(defaultVal);
  props.data[props.name] = defaultVal;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        {...props}
        disableToolbar
        inputVariant="outlined"
        // margin="normal"
        ampm={false}
        format="HH:mm dd.MM.yyyy"
        margin="dense"
        label={<Lang>{props.name}</Lang>}
        value={selectedDate}
        onChange={(date) => {
          props.data[props.name] = date;
          setSelectedDate(date);
          if (props.onEdit) {
            props.onEdit();
          }
        }}
        fullWidth
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export function EditSelectAutocomplete(props) {
  const [value, setValue] = React.useState("");
  let nProps = Object.assign({}, props);
  delete nProps["options"];
  delete nProps["error"];
  delete nProps["helperText"];

  useEffect(() => {
    let v =
      props.data[props.name] ||
      (props.options.length > 0 ? props.options[0][0] : "");
    props.data[props.name] = v; //parseInt(props.data[props.name], 10);
    let o = props.options.find((o) => o[0] === v);
    if (o) {
      setValue(o);
    }
  }, [props.options, props.data[props.name]]);

  const autocomplete = () => {
    return (
      <Autocomplete
        value={value}
        options={props.options}
        style={{ zIndex: 2000, maxHeight: 100, minWidth: 250 }}
        getOptionLabel={(option) => option[1]}
        onChange={(_, value) => {
          if (!value) {
            return;
          }
          props.data[props.name] = value[0];
          setValue(value);
          if (props.onChange) {
            props.onChange(props.data[props.name]);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.name}
            variant="outlined"
            margin="dense"
          />
        )}
      />
    );
  };

  if (!props.onAdd) {
    return autocomplete();
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={10}>
        {autocomplete()}
      </Grid>
      <Grid item xs={2} style={{ margin: "auto" }}>
        <Button variant="contained" color="primary" onClick={props.onAdd}>
          Shto
        </Button>
      </Grid>
    </Grid>
  );
}

export function EditSelect(props) {
  let [value, setValue] = React.useState("");
  let nProps = Object.assign({}, props);
  delete nProps["options"];
  delete nProps["error"];
  delete nProps["helperText"];

  useEffect(() => {
    let v =
      props.data[props.name] ||
      (props.options.length > 0 ? props.options[0][0] : "");
    props.data[props.name] = v; //parseInt(props.data[props.name], 10);
    //console.log(props.data[props.name]);
    setValue(v);
  }, [props.options, props.data[props.name]]);

  return (
    <FormControl
      variant="outlined"
      margin="dense"
      style={{ minWidth: 120 }}
      fullWidth
    >
      <TextField
        select
        label={<Lang>{props.name}</Lang>}
        margin="dense"
        variant="outlined"
        {...nProps}
        value={value}
        onChange={(e) => {
          if ((e.target.value + "").match(/^\d+$/)) {
            props.data[props.name] = parseInt(e.target.value, 10);
          } else {
            props.data[props.name] = e.target.value;
          }
          setValue(e.target.value);
          if (props.onChange) {
            props.onChange(props.data[props.name]);
          }
        }}
      >
        {props.options.map((o) => (
          <MenuItem key={o[0]} value={o[0]}>
            {o[1]}
          </MenuItem>
        ))}
      </TextField>
      {props.error ? <FormHelperText>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

export function EditCheckbox(props) {
  const trueVal = props.useBool ? true : 1;
  const falseVal = props.useBool ? false : 0;

  let defaultVal = props.data[props.name] || falseVal;
  props.data[props.name] = defaultVal;
  const [checked, setChecked] = React.useState(defaultVal);
  return (
    <FormControl fullWidth>
      <FormControlLabel
        label={<Lang>{props.name}</Lang>}
        control={
          <Checkbox
            checked={checked === trueVal}
            onChange={() => {
              props.data[props.name] =
                defaultVal === trueVal ? falseVal : trueVal;
              setChecked(defaultVal === trueVal ? falseVal : trueVal);
              if (props.onChange) {
                props.onChange(props.data[props.name]);
              }
            }}
            value="checkedA"
          />
        }
      />
    </FormControl>
  );
}

export function EditBranch(props) {
  let defaultVal = props.data[props.name] || "18446744073709551615";
  props.data[props.name] = defaultVal;
  const [val, setVal] = React.useState(defaultVal);
  //console.log(defaultVal);
  let toArr = () => {
    let num = BigNumber(val).toString(2).split("");
    if (num.length != 64) {
      let len = num.length;
      for (let i = 0; i < 64 - len; i++) {
        num.unshift("0");
      }
    }
    return num;
  };
  let nBranch = props.branches;
  return nBranch.map((b) => (
    <FormControl key={b[0]}>
      <FormControlLabel
        label={<Lang>{b[1]}</Lang>}
        control={
          <Checkbox
            checked={toArr()[63 - b[0]] === "1"}
            onChange={() => {
              let num = toArr();
              if (num[63 - b[0]] === "1") {
                if (b[0] !== 0) {
                  num[63] = "0";
                } else {
                  num = num.map((_) => "0");
                }
                num[63 - b[0]] = "0";
              } else {
                if (b[0] === 0) {
                  num = num.map((_) => "1");
                }
                num[63 - b[0]] = "1";
              }
              num = BigNumber("0b" + num.join("")).toString(10);
              props.data[props.name] = num;
              setVal(num);
            }}
          />
        }
      />
    </FormControl>
  ));
}

export function EditMultipleSelect(props) {
  let [values, setValues] = React.useState([]);
  let nProps = Object.assign({}, props);
  delete nProps["options"];
  delete nProps["error"];
  delete nProps["helperText"];

  useEffect(() => {
    setValues(props.data[props.name] || []);
  }, [props.options, props.data[props.name]]);

  const renderOptions = () => {
    let ret = [];
    if (!Array.isArray(props.options)) {
      Object.keys(props.options).forEach((menu) => {
        let items = props.options[menu];
        ret.push(
          <MenuItem key={menu}>
            <Checkbox
              onChange={(e) => {
                let nvalues = [];
                let items = props.options[menu].map((o) => o[0]);
                if (e.target.checked) {
                  nvalues = [...values, ...items];
                } else {
                  nvalues = values.filter((vv) => !items.includes(vv));
                }
                setValues(nvalues);
                props.data[props.name] = nvalues;
              }}
            />
            <ListItemText primary={menu} />
          </MenuItem>
        );
        items.forEach((o) => {
          ret.push(
            <MenuItem key={o[0]} value={o[0]} style={{ paddingLeft: "30px" }}>
              <Checkbox
                checked={values.includes(o[0])}
                onChange={(e) => {
                  let nvalues = [];
                  if (e.target.checked) {
                    nvalues = [...values, o[0]];
                  } else {
                    nvalues = values.filter((vv) => vv !== o[0]);
                  }
                  setValues(nvalues);
                  props.data[props.name] = nvalues;
                }}
              />
              <ListItemText primary={o[1]} />
            </MenuItem>
          );
        });
      });
    } else {
      return props.options.map((o) => {
        return (
          <MenuItem key={o[0]} value={o[0]}>
            <Checkbox
              checked={values.includes(o[0])}
              onChange={(e) => {
                let nvalues = [];
                if (e.target.checked) {
                  nvalues = [...values, o[0]];
                } else {
                  nvalues = values.filter((vv) => vv !== o[0]);
                }
                setValues(nvalues);
                props.data[props.name] = nvalues;
              }}
            />
            <ListItemText primary={o[1]} />
          </MenuItem>
        );
      });
    }
    return ret;
  };

  return (
    <FormControl
      variant="outlined"
      margin="dense"
      fullWidth
      error={props.error || false}
    >
      <InputLabel>{<Lang>{props.name}</Lang>}</InputLabel>
      <Select
        fullWidth
        {...nProps}
        multiple
        value={values}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Placeholder</em>;
          }
          return selected.join(", ");
        }}
      >
        {renderOptions()}
      </Select>
      {props.error ? <FormHelperText>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}
