import React from "react";
import CRUD from "../components/CRUD/crud";
import { NavLink } from "react-router-dom";
import { formatPrice, formatDateTime } from "../helpers";
import Lang from "../components/Lang/lang";
import CrudToolbar from "../components/CRUD/toolbar";
import Info from "../components/CRUD/info";
import { AddBtn, ExcelBtn, GroupBtn, PrintBtn } from "../components/CRUD/btn";
import { FormInline } from "../components/CRUD/form";
import { EditDateTime } from "../components/CRUD/fields";
import { subDays } from "date-fns";

export default class CashRegister extends CRUD {
  constructor(props) {
    super(props);
    this.object = "cashregister"; // server side object name
    this.state = {
      title: "Cash Register",
      columns: [
        // { key: "id", label: "ID", numeric: true },
        { key: "closed_on", label: "ClosedOn", render: formatDateTime },
        { key: "user", label: "User" },
        { key: "opened_on", label: "OpenedOn", render: formatDateTime },
        { key: "total", label: "Total", render: formatPrice, numeric: true , sum: true},
        {
          key: "perc",
          label: "Perc",
          render: (v) => v.toFixed(2) + "%",
          numeric: true,
        },
        { key: "info", label: "Info" },
        {
          key: "id",
          label: "List article's",
          render: (v) => (
            <NavLink to={"/cashregister/" + v}>List article's</NavLink>
          ),
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => <Lang>{v === 0 ? "Open" : "Closed"}</Lang>,
        },
      ],
      fromTo: { from: subDays(new Date(), 2), to: new Date()},
    };
  }

  toolbar = () => (
    <CrudToolbar
      count={(this.state.data || []).length}
      left={
        <Info
          info={this.props.params}
          title={<Lang>{this.state.title}</Lang>}
        />
      }
      onSearch={(e) => this.setState({ keyword: e.target.value.toLowerCase() })}
      right={
        <GroupBtn>
          <PrintBtn refPrint={this.printRef} />
          <ExcelBtn crud={this} />
          {this.createForm ? <AddBtn object={this.object} onClick={() => this.onEdit({})} /> : null}
        </GroupBtn>
      }
      leftForm={
        <FormInline>
          <EditDateTime name="from" data={this.state.fromTo} onEdit={this.doQuery}/>
          <EditDateTime name="to" data={this.state.fromTo} onEdit={this.doQuery}/>
        </FormInline>
      }
    />
  );

  doQuery = () => {
    this.setState({ data: [] });
    this.query({
      [this.object]: { 
        read: { 
          fields: this.state.columns.map((c) => c.key), 
          where: [
            "opened_on >= " + this.state.fromTo.from.toISOString(), 
            "opened_on <= " + this.state.fromTo.to.toISOString()
          ] 
      } },
    }).then((data) => {
      this.setState({ data: data[this.object].read });
    });
  }

  componentDidMount() {
    this.doQuery();
  }
}
