import React from "react";
import CRUD from "../components/CRUD/crud";

import { formatPrice } from "../helpers";

export default class SupplierItem extends CRUD {
  constructor(props) {
    super(props);
    this.object = "purchase_item"; // server side object name
    this.state = {
      title: "Supplier Items",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "name", label: "Article" },
        {
          key: "amount",
          label: "Qty",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
        { key: "price", label: "Price", render: formatPrice, numeric: true },
        { key: "total", label: "Total", render: formatPrice, numeric: true },
        {
          key: "vat",
          label: "Vat",
          render: (v) => v.toFixed(2) + "%",
          numeric: true,
        },
      ],
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["supplier_id = " + id],
        },
      },
      supplier: {
        read: {
          fields: ["name", "address", "contact", "debt"],
          where: ["id = " + id],
        },
      },
    }).then((data) => {
      let m = data.supplier.read[0];
      this.setState({
        params: {
          name: m[0],
          address: m[1],
          contact: m[2],
          debt: formatPrice(m[3]),
        },
        data: data[this.object].read,
      });
    });
  }
}
