import React from "react";
import CRUD from "../components/CRUD/crud";
import { GroupBtn, EditBtn, StateBtn } from "../components/CRUD/btn";
import {
  EditInput,
  EditSelect,
  EditSelectAutocomplete,
} from "../components/CRUD/fields";
import FormDialog, { FormDialog2 } from "../components/CRUD/form";

import { formatPrice, formatDate } from "../helpers";
import Query from "../api";

export default class PurchaseItem extends CRUD {
  constructor(props) {
    super(props);
    this.object = "purchase_item"; // server side object name
    this.state = {
      title: "Expense Items",
      addItem: false,
      addedItemId: 0,
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "konto", label: "Konto", numeric: true },
        { key: "name", label: "Article" },
        {
          key: "amount",
          label: "Qty",
          render: (v) => v.toFixed(2),
          numeric: true,
        },
        { key: "price", label: "Price", render: formatPrice, numeric: true },
        { key: "total", label: "Total", render: formatPrice, numeric: true },
        {
          key: "vat",
          label: "Vat",
          render: (v) => v.toFixed(2) + "%",
          numeric: true,
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    obj["purchase_id"] = parseInt(this.props.match.params.id, 10);
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="note" type="text" />
        <EditInput name="amount" type="number" />
        <EditInput name="price" type="number" />
        <EditSelectAutocomplete
          name="konto_id"
          options={data.konto.read}
          onChange={(value) => {
            const konto = this.state.formData.konto.read.find(
              (k) => k[0] === value
            );
            if (konto) {
              obj.vat_id = konto.vat_id;
              this.setState({});
            }
          }}
        />
        <EditSelect name="vat_id" options={data.vat.read} />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      konto: {
        read: {
          fields: ["id", "code", "desc", "vat_id"],
          where: ["state != 0", "type = EXPENSE"],
        },
      },
      vat: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      menu: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      unit: {
        read: {
          fields: ["id", "name"],
        },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      data.konto.read = data.konto.read.map((k) => [
        k[0],
        k[1] + " [" + k[2] + "]",
      ]);
      this.setState({ showForm: true, formData: data });
    });
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["purchase_id = " + id, "type = EXPENSE"],
        },
      },
      purchase: {
        read: {
          fields: ["invoice_nr", "supplier", "issued_on", "total"],
          where: ["id = " + id],
        },
      },
    }).then((data) => {
      let m = data.purchase.read[0];
      this.setState({
        params: {
          invoice_nr: m[0],
          supplier: m[1],
          issued_on: formatDate(m[2]),
          total: formatPrice(m[3]),
        },
        data: data[this.object].read,
      });
    });
  }
}
