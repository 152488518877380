import React from "react";
import { EditBtn, GroupBtn, StateBtn } from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import { EditDateTime, EditInput, EditSelect } from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";
import { formatDate, formatDateTime, formatPrice } from "../helpers";


export default class AssetsTrx extends CRUD {
  constructor(props) {
    super(props);
    this.object = "purchase_trx"; // server side object name
    this.state = {
      title: "Expense Trx",
      columns: [
        { key: "id", label: "ID", numeric: true },
        { key: "user", label: "User" },
        { key: "register", label: "Register" },
        { key: "exec_on", label: "ExecOn", render: formatDateTime },
        { key: "amount", label: "Amount", render: formatPrice, numeric: true },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    obj["invoice_id"] = parseInt(this.props.match.params.id, 10);
    if (!obj.id) {
      obj.amount = this.state.left;
    }
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="note" />
        <EditInput name="amount" type="number" />
        <EditDateTime name="exec_on" />
        <EditSelect name="register_id" options={data.register.read} />
      </FormDialog>
    );
  };

  onMapFields = (fields) => {
    return {
      ...fields,
      amount: -fields["amount"],
    };
  };

  onEdit = (row) => {
    let req = {
      register: {
        read: {
          fields: ["id", "name"],
        },
      },
    };

    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }

    this.query(req).then((data) => {
      if (row[this.key]) {
        data[this.object].one.amount *= -1;
      }
      this.setState({ showForm: true, formData: data });
    });
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["purchase_id = " + id],
        },
      },
      purchase: {
        read: {
          fields: ["invoice_nr", "supplier", "issued_on", "total", "payed"],
          where: ["id = " + id],
        },
      },
    }).then((data) => {
      let m = data.purchase.read[0];
      this.setState({
        params: {
          invoice_nr: m[0],
          supplier: m[1],
          issued_on: formatDate(m[2]),
          total: formatPrice(m[3]),
        },
        left: m[3] - m[4],
        data: data[this.object].read,
      });
    });
  }
}
