import React, { useState, useEffect } from "react";
import Query, { query } from "../api";
import {
  Paper as MPaper,
  Table as MTable,
  TableBody as MTableBody,
  TableRow as MTableRow,
  TableCell as MTableCell,
  CircularProgress as MCircularProgress,
  Typography as MTypography,
  TablePagination as MTablePagination,
  TableHead as MTableHead,
  TableSortLabel as MTableSortLabel,
  Toolbar as MToolbar,
  Grid as MGrid,
  InputAdornment as MInputAdornment,
  TextField as MTextField,
  Dialog as MDialog,
  DialogActions as MDialogActions,
  DialogContent as MDialogContent,
  DialogTitle as MDialogTitle,
  Button as MButton
} from "@material-ui/core";

import MSearchIcon from "@material-ui/icons/Search";

import { makeStyles } from "@material-ui/core/styles";
import Lang from "../components/Lang/lang";

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    minHeight: "30px",
    flexGrow: 1
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  container: {
    justifyContent: "space-between"
  }
}));

const Form = ({ title, children, data, isOpen, onSave, onClose }) => {
  const [errors, setErrors] = useState({});
  const id = data.id;
  return (
    <MDialog
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <MDialogTitle id="form-dialog-title">{title}</MDialogTitle>
      <MDialogContent>
        {React.Children.map(children, c =>
          c === null
            ? null
            : React.cloneElement(c, {
                key: c.props.name,
                data: c.props.data || data,
                error: errors[c.props.name] && errors[c.props.name].length > 0,
                helperText: (errors[c.props.name] || []).join(", ")
              })
        )}
      </MDialogContent>
      <MDialogActions>
        <MButton onClick={onClose} color="secondary">
          <Lang>Cancel</Lang>
        </MButton>
        <MButton onClick={() => onSave(false)} color="primary">
          <Lang>OK</Lang>
        </MButton>
        {id ? null : (
          <MButton onClick={() => onSave(true)} color="primary">
            <Lang>OK & Continue</Lang>
          </MButton>
        )}
      </MDialogActions>
    </MDialog>
  );
};

const Input = () => {
  return <input type="text" />;
};

const Toolbar = ({ left, right, rows, leftForm, onSearch }) => {
  const classes = useToolbarStyles();
  return (
    <MToolbar>
      <MGrid container>
        <MGrid container xs={12} className={classes.container}>
          <MGrid item className={classes.left}>
            {left}
          </MGrid>
          <MGrid item className={classes.right}>
            {right}
          </MGrid>
        </MGrid>
        <MGrid container xs={12} className={classes.container}>
          <MGrid item className={classes.left}>
            {leftForm || <MTypography>{0} Rows</MTypography>}
          </MGrid>
          <MGrid item className={classes.right}>
            <MTextField
              placeholder={"Search..."}
              onChange={onSearch}
              InputProps={{
                startAdornment: (
                  <MInputAdornment position="start">
                    <MSearchIcon />
                  </MInputAdornment>
                )
              }}
            />
          </MGrid>
        </MGrid>
      </MGrid>
    </MToolbar>
  );
};

const Header = ({ rows, cols, order, orderBy, onSort }) => {
  return (
    <MTableHead>
      <MTableRow>
        {cols.map((c, i) => (
          <MTableCell key={i} sortDirection={true} align={"right"}>
            <MTableSortLabel
              active={orderBy === i}
              direction={order}
              onClick={() => onSort(i)}
            >
              {c.sub
                ? "[" +
                  (c.render || (v => v))(
                    rows.reduce((acc, curr) => acc + curr[i], 0)
                  ) +
                  "]"
                : null}{" "}
            </MTableSortLabel>
            {c.key}
          </MTableCell>
        ))}
      </MTableRow>
    </MTableHead>
  );
};

const Footer = ({ rows, page, setPage }) => {
  const rowsPerPage = 22;
  return (
    <MTablePagination
      component="div"
      count={(rows || []).length}
      rowsPerPageOptions={[rowsPerPage]}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={(_, page) => setPage(page)}
      backIconButtonProps={{ "aria-label": "previous page" }}
      nextIconButtonProps={{ "aria-label": "next page" }}
    />
  );
};

const Table = ({ rows, cols, header, footer }) => {
  const [page, setPage] = useState(0);

  const JsxHeader = header;
  const JsxFooter = footer;

  return !rows ? (
    <div style={{ padding: 10, textAlign: "center" }}>
      <MCircularProgress />
      <MTypography>Loading...</MTypography>
    </div>
  ) : (
    <>
      <MTable stickyHeader size="small">
        {JsxHeader ? (
          <JsxHeader rows={rows} cols={cols} page={page} setPage={setPage} />
        ) : null}
        <MTableBody>
          {rows.map(row => (
            <MTableRow>
              {row.map((c, i) => (
                <MTableCell key={i}>
                  {cols[i].render ? cols[i].render(c, row) : c}
                </MTableCell>
              ))}
            </MTableRow>
          ))}
        </MTableBody>
      </MTable>
      {JsxFooter ? (
        <JsxFooter rows={rows} cols={cols} page={page} setPage={setPage} />
      ) : null}
    </>
  );
};

const Panel = ({ children }) => {
  return <MPaper>{children}</MPaper>;
};

const useQuery = q => {
  let emptyObj = {};
  Object.keys(q).forEach(k => {
    emptyObj[k] = {};
  });
  const [result, setResult] = useState(emptyObj);
  useEffect(() => {
    const aborter = new AbortController();
    Query(q, aborter)
      .then(result => {
        setResult(result);
      })
      .catch(console.error);
    return aborter.abort;
  }, []);
  return result;
};

const useForm = ({ title, open, onSave }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});
  return {
    title: title,
    data: data,
    onSave: () => onSave(data),
    onClose: () => setIsOpen(false),
    isOpen: isOpen,
    open: row => {
      setIsOpen(true);
      open(row, setData);
    }
  };
};

const Item = () => {
  const form = useForm({
    title: "Item form",
    object: "item",
    open: (id, setForm) => {
      query({
        item: {
          one: id
        }
      }).then(result => {
        console.log(result);
        setForm(result.item.one);
      });
    },
    onSave: (form, setErrors) => {
      // mutate({
      //   item: {
      //     update: form,
      //     one: id
      //   }
      // })
      //   .then(result => {})
      //   .catch(setErrors);
      // console.log(form);
    }
  });

  const cols = [
    { key: "id", numeric: true },
    { key: "name" },
    {
      key: "id",
      render: v => <MButton onClick={() => form.open(v)}>Open</MButton>
    }
  ];

  const result = useQuery({
    item: {
      read: {
        fields: cols.map(c => c.key)
      }
    }
  });

  return (
    <>
      <Form {...form}>
        <Input name="name" />
      </Form>
      <Panel>
        <Toolbar left={<b>Hello</b>} right={<b>Right</b>} />
        <Table
          cols={cols}
          rows={result.item.read}
          header={Header}
          footer={Footer}
        />
      </Panel>
    </>
  );
};

export default Item;
