import Alert from "./alert";
import { saveAs } from "file-saver";

let History = null;

export function setHistory(history) {
  History = history;
}

export default function Query(request, abortController) {
  return Post("/q", request, abortController);
}

export function query(request) {
  return Post("/q", request);
}

export function Post(url, request, abortController) {
  abortController = abortController || new AbortController();
  const { signal } = abortController;
  return new Promise((resolve, reject) => {
    fetch(url, {
      signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(request) //#ba8641
    }).then(d => {
      if (d.status === 200) {
        d.json().then(response => {
          if (response.error) {
            Object.keys(response.error).forEach(k => {
              const r = response.error[k];
              if (r.type === "error") {
                Alert("danger", r.msg);
              } else if (r.type === "ok") {
                Alert("danger", r.msg);
              } else if (r.type === "field") {
                resolve(response.error);
              }
            });
            return;
          } else if (response.alert) {
            Alert(response.alert + "", response.msg + "");
            reject(response.msg);
            return;
          }
          resolve(response.data);
        });
      } else if (d.status === 401) {
        reject(d.statusText);
        if (History) {
          History.replace("/auth");
        }
      }
    });
  });
}

export function Download(url, request, abortController) {
  abortController = abortController || new AbortController();
  const { signal } = abortController;
  return new Promise((resolve, reject) => {
    fetch(url, {
      signal,
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(request) //#ba8641
    })
      .then(d => d.blob())
      .then(blob => {
        saveAs(blob, "sale.xlsx");
      });
  });
}
