import { Button, Dialog } from "@material-ui/core";
import React from "react";
import Barcode from "react-barcode";
import { NavLink } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
  EditBtn, GroupBtn,
  PrintBtnRow, StateBtn
} from "../components/CRUD/btn";
import CRUD from "../components/CRUD/crud";
import { EditBranch, EditInput, EditSelect } from "../components/CRUD/fields";
import FormDialog, { FormDialogBody } from "../components/CRUD/form";
import Lang from "../components/Lang/lang";
import { formatPrice } from "../helpers";

export default class Item extends CRUD {
  constructor(props) {
    super(props);
    this.object = "item"; // server side object name
    this.refBarcode = null;
    this.state = {
      barcodeModal: null,
      title: "Items",
      create_base_article: false,
      columns: [
        { key: "id", label: "ID", numeric: true },
        {
          key: "barcode",
          label: "Barcode",
          render: (v, row) => (
            <PrintBtnRow
              name={v}
              onClick={() => {
                this.setState({
                  barcodeModal: {
                    barcode: v,
                    name: row[3],
                    price: row[4],
                  },
                });
              }}
            />
          ),
        },
        { key: "name", label: "Name" }, //key as in db name
        { key: "menu", label: "Menu" },
        { key: "price1", label: "Price", render: formatPrice, numeric: true },
        {
          key: "vat",
          label: "Vat",
          render: (v) => v.toFixed(0) + "%",
          numeric: true,
        },
        {
          key: "type",
          label: "Type",
          render: (v) => ["Artikull Baze", "Artikull", "Oferte"][v],
        },
        { key: "unit", label: "Unit" },
        {
          key: "id",
          label: "List ingredient's",
          render: (v, row) =>
            row[6] === 0 ? (
              <Lang>Artikull Baze</Lang>
            ) : (
              <NavLink to={"/ingredient/" + v}>List ingredient's</NavLink>
            ),
        },
        {
          key: "img",
          label: "Image",
          render: (v, row) => (
            <button
              onClick={() => {
                this.setState({
                  image: true,
                  showForm: true,
                  formData: { [this.object]: {}, row: row, img: v },
                });
              }}
            >
              Image
            </button>
          ),
        },
        {
          key: "state",
          label: "Action",
          render: (v, row) => (
            <GroupBtn>
              <EditBtn onClick={() => this.onEdit(row)} />
              <StateBtn
                state={v}
                onClick={(v) => this.onSaveForm(row, { state: v })}
              />
            </GroupBtn>
          ),
        },
      ],
    };
  }

  createForm = (data) => {
    if (this.state.image) {
      return (
        <FormDialogBody
          crud={this}
          title="Image"
          onSave={() => {
            if (this.state.img) {
              const formData = new FormData();
              formData.append("img", this.state.img);

              fetch("/u?t=item&id=" + data.row[this.key], {
                method: "POST",
                body: formData,
              })
                .then((response) => {
                  response.text().then((file) => {
                    data.row[
                      this.state.columns.findIndex((c) => c.key === "img")
                    ] = file;
                    console.log(file);
                    this.setState({
                      img: undefined,
                      showForm: false,
                      formData: {},
                    });
                  });
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              this.setState({
                img: undefined,
                showForm: false,
                formData: {},
              });
            }
          }}
        >
          <>
            <img src={"/static/images/" + data.img} height="200" />
            <input
              type="file"
              name="img"
              accept="image/png"
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  this.setState({ img: e.target.files[0] });
                }
              }}
            />
          </>
        </FormDialogBody>
      );
    }
    let obj = data[this.object].one;
    if (!obj.id) {
      obj.type = 0;
      obj.branch = "18446744073709551615";
    }
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="barcode" />
        <EditInput name="name" />
        <EditSelect name="menu_id" options={data.menu.read} />
        <EditInput name="price1" type="number" />
        <EditSelect name="unit_id" options={data.unit.read} />
        <EditSelect name="vat_id" options={data.vat.read} />
        <EditSelect
          name="type"
          options={[
            [0, "Artikull Baze"],
            [1, "Artikull"],
            [2, "Oferte"],
          ]}
        />
        <EditInput name="note" />
        <EditBranch name="branch" branches={data.branch.read} />
      </FormDialog>
    );
  };

  /*
          <EditInput name="price2" type="number" />
        <EditInput name="prep_mins" type="number" />
        <EditTime name="from" />
        <EditTime name="to" />
        <EditCheckbox name="star" />
  */

  onEdit = (row) => {
    let req = {
      menu: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      vat: {
        read: {
          fields: ["id", "name"],
          where: ["state != 0"],
        },
      },
      unit: {
        read: {
          fields: ["id", "name"],
        },
      },
      branch: { read: { fields: ["id", "name"] } },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      data.menu.read = [[0, "-"], ...data.menu.read];
      this.setState({ showForm: true, formData: data, image: false });
    });
  };

  render = () => {
    return (
      <>
        <Dialog
          open={this.state.barcodeModal !== null}
          onClose={() => this.setState({ barcodeModal: null })}
        >
          {this.state.barcodeModal !== null ? (
            <>
              <div ref={(r) => (this.refBarcode = r)} style={{ width: "6cm" }}>
                <center>
                  <b style={{ fontSize: 18, fontFamily: "monospace" }}>
                    {this.state.barcodeModal.name}
                  </b>
                </center>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "2cm",
                  }}
                >
                  <Barcode value={this.state.barcodeModal.barcode} />
                  <b style={{ fontSize: 45, fontFamily: "monospace" }}>
                    {this.state.barcodeModal.price.toFixed(2)}&euro;
                  </b>
                </div>
              </div>
              <ReactToPrint
                trigger={() => (
                  <Button color="primary">
                    <Lang>Print</Lang>
                  </Button>
                )}
                content={() => this.refBarcode}
              />
            </>
          ) : null}
        </Dialog>
        {super.render()}
      </>
    );
  };
}
