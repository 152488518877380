import React from 'react';
import CRUD from '../components/CRUD/crud';
import { GroupBtn, EditBtn, StateBtn } from '../components/CRUD/btn';
import { EditInput, EditSelect, EditBranch } from '../components/CRUD/fields';
import FormDialog from '../components/CRUD/form';

export default class Table extends CRUD {
  constructor(props) {
    super(props);
    this.object = 'table'; // server side object name
    this.state = {
      title: "Tables",
      columns: [
        {key: "id", label: "ID", numeric: true},
        {key: "name", label: "Name", numeric: true},//key as in db name
        {key: "position", label: "Position", numeric: true, 
          render: (v) => "Kolona: " + v.split("x")[0] + ", Rreshti:" + v.split("x")[1]},
        {key: "size", label: "Size", numeric: true},
        {key: "state", label: "Action", render: (v, row) => (
          <GroupBtn>
            <EditBtn onClick={() => this.onEdit(row)}/>
            <StateBtn state={v} onClick={(v) => this.onSaveForm(row, {state: v})}/>
          </GroupBtn>
        )},
      ]
    }
  }

  createForm = (data) => {
    let obj = data[this.object].one;
    obj["table_category_id"] = this.props.match.params.id;
    if (!obj['name']) {
      obj['name'] = "Tavolina ";
      obj['width'] = '1';
      obj['height'] = '1';
    }
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name='name'/>
        <EditInput name='width' type='number'/>
        <EditInput name='height' type='number'/>
        <EditInput name='x' type='number'/>
        <EditInput name='y' type='number'/>
        <EditSelect name='table_category_id' options={data.table_category.read} />
        <EditBranch name='branch' branches={data.branch.read}/>
      </FormDialog>
    );
  }

  onEdit = (row) => {
    let req = {
      table_category: { read: { fields: ["id", "name"] } },
      branch: { read: { fields: ["id", "name"] } }
    }
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] }
    }
    this.query(req).then(data => {
      this.setState({showForm: true, formData: data})
    })
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map(c => c.key),
          where: ["table_category_id = " + id, "state = 1"]
        } 
      },
      table_category: {
        read: {
          fields: ["name"],
          where: ["id = " + id]
        }
      }
    }).then(data => {
      let m = data.table_category.read[0];
      this.setState({
        params: { table_category: m[0] },
        data: data[this.object].read
      });
    })
  }
}