import React from "react";
import { EditDate, EditInput, EditSelect } from "../components/CRUD/fields";
import FormDialog from "../components/CRUD/form";
import Purchase from "../purchase/purchase";

export default class SupplierPurchase extends Purchase {
  createForm = (data) => {
    let obj = data[this.object].one;
    obj["supplier_id"] = parseInt(this.props.match.params.id, 10);
    obj["type"] = "PURCHASE";
    return (
      <FormDialog crud={this} data={obj}>
        <EditInput name="invoice_nr" />
        <EditDate name="issued_on" />
        <EditSelect name="konto_id" options={data.konto.read} />
        <EditSelect name="branch_id" options={data.branch.read} />
      </FormDialog>
    );
  };

  onEdit = (row) => {
    let req = {
      konto: {
        read: {
          fields: ["id", "code", "desc"],
          where: ["state != 0", "type = PURCHASE"],
        },
      },
      branch: {
        read: { fields: ["id", "name"] },
      },
    };
    if (row[this.key]) {
      req[this.object] = { one: row[this.key] };
    }
    this.query(req).then((data) => {
      data.konto.read = data.konto.read.map((k) => [
        k[0],
        k[1] + " [" + k[2] + "]",
      ]);
      this.setState({ showForm: true, formData: data });
    });
  };

  doQuery = () => {
    this.setState({ data: [] });
    this.query({
      [this.object]: {
        read: {
          fields: this.state.columns.map((c) => c.key),
          where: ["type = PURCHASE", "supplier_id = " + this.props.match.params.id, "issued_on >= " + this.state.fromTo.from.toISOString(), "issued_on <= " + this.state.fromTo.to.toISOString()],
        },
      },
    }).then((data) => {
      this.setState({ data: data[this.object].read });
    });
  }

  componentDidMount() {
    this.doQuery();
  }

  // componentDidMount() {
  //   this.query({
  //     [this.object]: {
  //       read: {
  //         fields: this.state.columns.map((c) => c.key),
  //         where: ["supplier_id = " + this.props.match.params.id],
  //       },
  //     },
  //   }).then((data) => {
  //     this.setState({ data: data[this.object].read });
  //   });
  // }
}
